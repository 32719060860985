import { defineStore } from 'pinia'

export const useThemeStore = defineStore('selectedTheme', {
    state: () => {
      return {
        selectedTheme: 'orange' //green, orange, blue, purple, darkpink
      }
    },
    actions: {
      setSelectedTheme(theme) {
        this.selectedTheme = theme
      }
    },
    persist: true,
  })