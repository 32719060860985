<script setup>
import { defineProps, computed } from 'vue';
import { useModeStore } from '../store/mode.js';
// import { useThemeStore } from '../store/theme.js';
// import AddToPlaylistDropdown from './AddToPlaylistDropdown.vue';
import playIcon from '../assets/icons/play_icon.png';
import router from "../router/index.js";

const props = defineProps({
    // id: Number, no more using id as we need to determine the playlist type first
    type: String,
    playlistId: Number,
    userPlaylistId: Number,
    assetBroadcastId: Number,
    image: String,
    minutes: Number,
    currentSeconds: Number,
    title: String,
    elapsedTime: String,
    isTeacherPlaylist: Boolean,
    assetId: Number,
    playFrom: Number,
    playlistType: String, //AssetBroadcast
});

// Determine the ID based on the type
const id = computed(() => {
    switch (props.type) {
        case 'Playlist':
            return props.playlistId;
        case 'UserPlaylist':
            return props.userPlaylistId;
        case 'AssetBroadcast':
            return props.assetBroadcastId;
        default:
            throw new Error('Unknown playlist type');
    }
});

const modeStore = useModeStore();
// const themeStore = useThemeStore();

const progress = (props.currentSeconds / 60 / props.minutes) * 100 + '%';

//const selectedFile = ref(null);
//const showCardOnBottom = ref(true);

// Function to format time as hh:mm:ss
const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 60 / 60);
    const minutes = Math.floor(totalSeconds / 60 % 60);
    const seconds = Math.floor(totalSeconds % 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

// Format current time and total time
const formattedCurrentTime = formatTime(props.currentSeconds);
const formattedTotalTime = formatTime(props.minutes);

const goToPlaylistPlay = () => {
    const query = {
        isTeacherPlaylist: props.isTeacherPlaylist,
    };
    
    if (props.playFrom !== null && props.playFrom !== undefined) {
        query.playFrom = props.playFrom;
    }
    
    if (props.assetId !== null && props.assetId !== undefined) {
        query.assetId = props.assetId;
    }

    if (props.type === 'AssetBroadcast') {
      router.push({
        name: 'DocumentDetailed',
        params: { id: id.value },
        query: query,
      });
    } else {
      router.push({
        name: 'Play Playlist',
        params: { id: id.value },
        query: query,
      });
    }
}
</script>

<template>
    <div 
        class="card card-compact rounded-xl p-2 cursor-pointer"
        :class="{'hover:bg-white hover:bg-opacity-30 text-black': modeStore.selectedMode === 'light', 'hover:bg-white hover:bg-opacity-10 text-white': modeStore.selectedMode === 'dark'}"
    >
        <!-- <div class="relative w-full rounded-xl">
            <img class="min-w-40 max-w-40 md:max-w-full md:min-w-56 w-full object-contain" :src="image" alt="Recently Watched Image" />
            <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <img src="../assets/icons/recentlywatched_playbtn.png" alt="play button" class="cursor-pointer h-8 md:h-full">
            </div>
            <div :class="{ 'theme-green': themeStore.selectedTheme === 'green', 'theme-orange': themeStore.selectedTheme === 'orange', 'theme-blue': themeStore.selectedTheme === 'blue', 'theme-purple': themeStore.selectedTheme === 'purple', 'theme-darkpink': themeStore.selectedTheme === 'darkpink'  }" class="absolute z-50 right-5 top-5 cursor-pointer">
                <AddToPlaylistDropdown :currentFileId="props.id"/>
            </div>
        </div> -->

        <figure @click="goToPlaylistPlay" class="w-full relative rounded-xl xl:h-60 2xl:h-96">
            <img class="w-full" :src="image" alt="Playlist Card Image" />
            <img class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-width-1/5 max-height-1/5 cursor-pointer"  :src="playIcon" alt="Play Icon"/>
        </figure>
        <!-- <div :class="{ 'theme-green': themeStore.selectedTheme === 'green', 'theme-orange': themeStore.selectedTheme === 'orange', 'theme-blue': themeStore.selectedTheme === 'blue', 'theme-purple': themeStore.selectedTheme === 'purple', 'theme-darkpink': themeStore.selectedTheme === 'darkpink'  }" class="absolute z-50 right-5 top-5 cursor-pointer">
            <AddToPlaylistDropdown :currentFileId="props.id"/>
        </div> -->

        <div class="py-2">
           <div v-show="props.playlistType === 'AssetBroadcast'" v-if="formattedTotalTime !== '00:00:00'" class="flex md:text-base py-2">
               <div class="pr-2 text-[10px]">{{ formattedCurrentTime }}</div>
               <div class="w-full my-auto mx-1 h-1 bg-gray-300 rounded-full overflow-hidden">
                   <div :class="modeStore.selectedMode === 'dark' ? 'bg-white' : 'bg-black'" class="h-full" :style="{ width: progress }"></div>
               </div>
               <div class="pl-2 text-[10px]">{{ formattedTotalTime }}</div>
           </div>
            <h2 class="text-sm md:text-lg font-semibold w-full min-h-[60px] line-clamp-2">{{ props.title }}</h2>
            <!-- <div class="text-xs">{{ props.elapsedTime }}</div> -->
        </div>
    </div>
</template>