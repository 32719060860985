<template>
  <div class="flex space-x-2">
    <div
      v-for="tab in tabs"
      :key="tab.value"
      @click="updateActiveTab(tab.value)"
      :class="[
        activeTab === tab.value ? 'bg-orange-500 text-white' : 
          (modeStore.selectedMode === 'dark' ? 'bg-black text-white' : 'bg-gray-50 text-black'),
        'px-4 py-2 rounded-md cursor-pointer hover:bg-orange-400 hover:text-white'
      ]"
    >
      {{ $t(tab.label) }}
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { useModeStore } from '../store/mode.js';
const modeStore = useModeStore();

defineProps({
  activeTab: {
    type: String,
    required: true
  },
  tabs: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(['update:activeTab']);


const updateActiveTab = (tabValue) => {
  emit('update:activeTab', tabValue);
};
</script>