import { defineStore } from 'pinia'

export const useActivePageStore = defineStore('selectedActivePage', {
    state: () => {
      return {
        selectedActivePage: 1
      }
    },
    actions: {
      setSelectedActivePage(page) {
        this.selectedActivePage = page
      }
    }
  })