<script>
import { useModeStore } from '../store/mode.js';
import { getPaginationBackgroundColor } from '../utils/commonFunctions.js';

import PreviousPageBlack from '../assets/icons/previous_page_black.png';
import PreviousPageWhite from '../assets/icons/previous_page_white.png';
import NextPageBlack from '../assets/icons/next_page_black.png';
import NextPageWhite from '../assets/icons/next_page_white.png';

export default {
    props: {
        showingFrom: Number,
        showingTo: Number,
        totalItems: Number,
        totalPages: Number,
        currentPage: Number,
        itemsPerPage: Number,
    },
    setup() {
        const modeStore = useModeStore();
        const paginationBackgroundColor = getPaginationBackgroundColor();

        return {
            modeStore,
            PreviousPageBlack,
            PreviousPageWhite,
            NextPageBlack,
            NextPageWhite,
            paginationBackgroundColor,
        };
    },
    mounted() {
        console.log('this.totalPages, this.currentPage, this.itemsPerPage',this.totalPages, this.currentPage, this.itemsPerPage);
    },
    computed: {
        pages() {
            const pages = [];
            for (let i = 1; i <= this.totalPages; i++) {
                if (i <= 3 || i > this.totalPages - 3 || this.totalPages <= 6) {
                    pages.push(i);
                } else if (i === 4) {
                    pages.push('...');
                }
            }
            console.log(pages);
            return pages;
        },
        showNextButton() {
            return this.totalItems > this.currentPage * this.itemsPerPage;
        },
    },
}
</script>

<template>
    <div class="flex items-center justify-between p-2">
        <!-- Pagination Description -->
        <div class="pagination-description" :class="{ 'text-gray-500': modeStore.selectedMode === 'light'}">
            <!-- Memaparkan {{ showingFrom }} hingga {{ showingTo }} daripada {{ totalItems }} hasil -->
            {{ $t('pagination.details', {firstItem: showingFrom, lastItem: showingTo, totalItems: totalItems})}}
        </div>

        <!-- Pagination Buttons -->
        <div v-show="totalPages > 1" class="join rounded-md">
            <!-- Previous Page Icon -->
            <button
                class="join-item btn border-none"
                :class="modeStore.selectedMode === 'light' ? 'text-black bg-custom-light-gray' : 'text-white bg-black bg-opacity-30'"
                v-if="currentPage > 1"
                @click="$emit('change-page', currentPage - 1)"
            >
                <img
                    :src="modeStore.selectedMode === 'light' ? PreviousPageBlack : PreviousPageWhite"
                    alt="Previous Page Icon"
                />
            </button>

            <!-- Page Numbers -->
            <button
                v-for="(page, index) in pages"
                :key="index"
                class="join-item btn border-none"
                :class="page == currentPage ? paginationBackgroundColor : modeStore.selectedMode === 'light' ? 'text-black bg-custom-light-gray' : 'text-white bg-black bg-opacity-30'"
                @click="page !== currentPage && $emit('change-page', page)"
            >
                {{ page }}
            </button>

            <!-- Next Page Icon -->
            <button
                class="join-item btn border-none"
                :class="modeStore.selectedMode === 'light' ? 'text-black bg-custom-light-gray' : 'text-white bg-black bg-opacity-30'"
                v-if="showNextButton"
                @click="$emit('change-page', currentPage + 1)"
            >
                <img 
                    :src="modeStore.selectedMode === 'light' ? NextPageBlack : NextPageWhite"
                    alt="Next Page Icon"
                />
            </button>
        </div>
    </div>
</template>

<style scoped>
    .text-black {
        color: black;
        border: none;
    }
</style>