import { defineStore } from 'pinia';
import { usePlaybackStore } from './playbackStore.js';
import { nextTick } from 'vue';

export const useUserStore = defineStore('user', {
  state: () => ({
    isLoggedIn: false,
    user: null,
    currentlyPlayingPlaylist: null,
    currentlyPlayingDocument: null,
    currentPlayback: null,
    stats: {
      total_hour_spent: 0,
      unique_playlist_watched: 0,
      total_added_item_count: 0
    },
  }),
  actions: {
    login(user) {
      this.isLoggedIn = true;
      this.user = user;
    },
    async logout() {
      const playbackStore = usePlaybackStore();
      await nextTick();
      this.$patch((state) => {
        state.isLoggedIn = false;
        state.user = null;
        state.currentlyPlayingPlaylist = null;
        state.currentlyPlayingDocument = null;
        state.currentPlayback = null;
        state.stats = {
          total_hour_spent: 0,
          unique_playlist_watched: 0,
          total_added_item_count: 0
        };
      });

      playbackStore.$reset();

      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
    },
    setCurrentlyPlayingPlaylist(playlist, assetBroadcastItem) {
      this.currentlyPlayingDocument = null;

      if (!this.currentlyPlayingPlaylist) {
        this.currentlyPlayingPlaylist = {};
      }
      this.currentlyPlayingPlaylist.playlist = playlist;
      this.currentlyPlayingPlaylist.assetBroadcastItem = assetBroadcastItem;
    },
    setCurrentlyPlayingDocument(document) {
      this.currentlyPlayingPlaylist = null;
      this.currentlyPlayingDocument = document;
    },
    setUserStats(stats) {
      this.stats = stats;
    }
  },
  persist: true,
});