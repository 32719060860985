<script setup>
import { ref, onMounted, nextTick, computed  } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import { useModeStore } from '../store/mode.js';
import useIsMobile from '../composables/isMobile.js';
import { useUserStore } from '../store/user.js';

// import { getStrokeColor, getModeStrokeColor } from '../utils/commonFunctions.js';
import { useActivePageStore } from '../store/activePageTab.js';
import { usePlaybackStore } from '../store/playbackStore.js';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

import PageNavigator from '../components/PageNavigator.vue';
import PlayerControls from '../components/PlayerControls.vue';
// import PlayerVolumeControls from '../components/PlayerVolumeControls.vue';

const videoPlayer = ref(null);
const isPlaying = computed(() => playbackStore.isPlaying);
const userStore = useUserStore();
const playbackStore = usePlaybackStore();
const pagePlayerElement = ref(null);

const rewind = () => {
  videoPlayer.value.currentTime -= 15;
  updateAssetBroadcastProgress(assetData.value.id)
};
const skipPrev = () => {
  // Logic to skip to the previous video
};

const togglePlay = () => {
    playbackStore.updatePlayingState(!playbackStore.isPlaying);
    if (playbackStore.isPlaying) {
        videoPlayer.value.play();
    } else {
        videoPlayer.value.pause();
    }
};

const skipNext = () => {
  // Logic to skip to the next video
};
const forward = () => {
  videoPlayer.value.currentTime += 15;
};
// const onVolumeChange = (volume) => {
//   videoPlayer.value.volume(volume);
// };

// const toggleFullscreen = () => {
//   if (videoPlayer.value.isFullscreen()) {
//     videoPlayer.value.exitFullscreen();
//   } else {
//     videoPlayer.value.requestFullscreen();
//   }
// };

// const popOut = () => {
//   // Logic to pop out the video
//   videoPlayer.value.requestPictureInPicture();
// };

const updateCurrentlyPlayingDocument = (document) => {
  userStore.setCurrentlyPlayingDocument(document);
  playbackStore.updateCurrentVideoPlayerElement(videoPlayer);
};

const modeStore = useModeStore();
const activePageStore = useActivePageStore();
const { isMobile } = useIsMobile();
// const strokeColor = getStrokeColor();
// const modeStrokeColor = getModeStrokeColor();

const route = useRoute();
const assetId = ref(route.params.id);
const assetData = ref(null);
const isLoading = ref(true);

const fetchAssetData = async () => {
  try {
    const response = await axios.get(`/api/v1/asset-broadcasts/${assetId.value}`);
    assetData.value = response.data;
    // update once when user clicked into here.
    updateAssetBroadcastProgress(assetData.value.id);
    nextTick(() => {
        if (assetData.value.content_type === 1) { // video type
            videoPlayer.value = videojs(document.querySelector('.video-js'), {
                controls: true, // hide the default controls since we using PlayerControls component
            });

            nextTick(() => {
                // check if route has query playFrom then set the current time of video to it
                if (route.query.playFrom) {
                    videoPlayer.value.currentTime(route.query.playFrom); // in seconds
                    try {
                        // muted first
                        videoPlayer.value.muted(true);
                        // auto play if user interacted with document first
                        videoPlayer.value.play();

                        // update the playback store isPlaying
                        playbackStore.updatePlayingState(true);
                    } catch (error) {
                        console.error('Failed to play video:', error);
                    }
                }

                videoPlayer.value.on('play', () => {
                    if (!playbackStore.isPlaying) {
                        playbackStore.updatePlayingState(true);
                    }
                });
                videoPlayer.value.on('pause', () => {
                    if (playbackStore.isPlaying) {
                        playbackStore.updatePlayingState(false);
                        updateAssetBroadcastProgress(assetData.value.id)
                    }
                });

                // on current time changes
                videoPlayer.value.on('timeupdate', () => {
                    playbackStore.updateCurrentTime(videoPlayer.value.currentTime());

                    if (videoPlayer.value.currentTime() % 10 === 0) {
                      updateAssetBroadcastProgress(assetData.value.id)
                    }
                });

                // duration of video
                videoPlayer.value.on('durationchange', () => {
                    playbackStore.updateDuration(videoPlayer.value.duration());
                });

                updateCurrentlyPlayingDocument(assetData.value);    
            });     
        }
    });
  } catch (error) {
    console.error('Failed to fetch asset data:', error);
  } finally {
    isLoading.value = false;
  }
};


const breadcrumbs = [
  { text: 'Laman Utama', to: { name: 'Dashboard Page' } },
  { text: 'Fails', to: { name: 'Document Page' } },
  { text: 'Fail' },
];

const updateAssetBroadcastProgress = (assetBroadcastId) => {
  const apiUrl = `/api/v1/asset-broadcasts/${assetBroadcastId}/progress`
  const current_seconds = (videoPlayer.value && videoPlayer.value.currentTime()) ? videoPlayer.value.currentTime() : 0;
  axios
      .post(apiUrl, { asset_broadcast_id: assetBroadcastId, current_seconds: current_seconds })
      .then(() => {
        console.log('Asset broadcast progress updated successfully');
      })
      .catch((error) => {
        console.error('Failed to update asset broadcast progress:', error);
      });
};

onMounted(() => {
  activePageStore.setSelectedActivePage(5);
  fetchAssetData();
});

// watch(isPlaying, (newValue) => {
//   // if there's changes to isPlaying
//   if (newValue && videoPlayer.value.paused()) {
//     videoPlayer.value.play();
//   } else if (!newValue && !videoPlayer.value.paused()) {
//     videoPlayer.value.pause();
//   }
// });

</script>

<template>
  <div class="min-h-screen">
    <page-navigator :breadcrumbs="breadcrumbs" />
    <div :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}" class="flex justify-between justify-items-center my-auto p-4">
      <h2 :class="{'mobile-title': isMobile, 'page-top-title': !isMobile}">{{ assetData?.title }}</h2>
    </div>
    <section 
      class="flex flex-col gap-8 rounded-xl p-4 bg-transparent"
      :class="{'text-black': modeStore.selectedMode === 'light', ' text-white': modeStore.selectedMode === 'dark'}"
    >
      <div v-if="isLoading" class="flex justify-center items-center h-64">
        <div class="loader"></div>
      </div>
      <div v-else>
        <div v-if="assetData.content_type === 1" class="video-container">
            <video ref="pagePlayerElement" class="video-js" preload="auto">
                <source :src="assetData.file_url" type="video/mp4" />
                <p class="vjs-no-js">
                To view this video please enable JavaScript, and consider upgrading to a
                web browser that
                <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                </p>
          </video>
        </div>
        <div v-else-if="assetData.content_type === 2" class="interactive-container">
          <iframe :src="assetData.file_url" frameborder="0" allowfullscreen></iframe>
        </div>
        <div v-else-if="assetData.content_type === 3" class="document-container">
          <embed :src="assetData.file_url" type="application/pdf" width="100%" height="600px" />
        </div>
        <div class="asset-details my-4 flex flex-row justify-between content-center items-center">
            <div class="flex flex-row gap-3 col-span-2 w-1/5 justify-start max-w-[400px]">
                <img :src="assetData.thumbnails[0].url" alt="Asset Thumbnail" class="w-24 h-24 object-cover mb-4" />

                <div class="flex flex-col gap-2 overflow-hidden">
                    <h2 class="font-bold truncate overflow-clip">{{ assetData.title }}</h2>
                    <router-link :to="{'name' : 'Teacher Detail', params: {id: assetData.created_by.id}}" class="text-gray-500">{{ $t('general.by') }} {{ assetData.created_by.name }}</router-link>
                    <!-- <div class="tags">
                        <span v-for="tag in assetData.tags" :key="tag.id" class="inline-block bg-gray-200 rounded-full px-3 py-2 text-sm font-semibold text-gray-700 mr-2">
                        {{ tag.name }}
                        </span>
                    </div> -->
                </div>
            </div>
            <div class="w-3/5 justify-center" v-show="assetData.content_type === 1">
                <player-controls
                    @rewind="rewind"
                    @skip-prev="skipPrev"
                    @toggle-play="togglePlay"
                    @skip-next="skipNext"
                    @forward="forward"
                    :current-playing="isPlaying"
                ></player-controls>
            </div>
            <div class="w-1/5 justify-end max-w-[200px]"  v-show="assetData.content_type === 1">
                <!-- Volumn controls-->
                <!-- <player-volume-controls
                    @volume-change="onVolumeChange"
                    @toggle-fullscreen="toggleFullscreen"
                    @pop-out="popOut"
                ></player-volume-controls> -->
            </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video-container .video-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.interactive-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.interactive-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.document-container {
  margin-bottom: 20px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>../components/PlayerVolumnControls.vue/index.js