import { ref, onMounted, onBeforeUnmount } from 'vue';

export default function useIsMobile() {
  const isMobile = ref(window.innerWidth <= 768);

  const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
  };

  onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', updateIsMobile);
  });

  return { isMobile };
}