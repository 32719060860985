<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import axios from 'axios'
// import router from "../router/index.js";
import { useModeStore } from '../store/mode.js';
import useIsMobile from '../composables/isMobile.js';
import { useThemeStore } from '../store/theme.js';
// import { useUserStore } from '../store/user.js';
import { useActivePageStore } from '../store/activePageTab.js';
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';

import PageNavigator from '../components/PageNavigator.vue';
import CustomPagination from '../components/CustomPagination.vue';
// import PlaylistCard from '../components/PlaylistCard.vue'
import TeachersCard from '../components/TeachersCard.vue'
import PlaylistCardForTeacher from '../components/PlaylistCardForTeacher.vue';
import CreateEditPlaylistModal from '../components/CreateEditPlaylistModal.vue';
import NotificationTopBar from '../components/NotificationTopBar.vue';
import PlaylistCardSkeletonLoader from '../components/PlaylistCardSkeletonLoader.vue'
import TabList from '../components/TabsComponent.vue';

import blackFilterIcon from '../assets/icons/black_filter_icon.svg';
import whiteFilterIcon from '../assets/icons/white_filter_icon.svg';

const addToPlaylistStore = useAddToPlaylistStore();
const modeStore = useModeStore();
const themeStore = useThemeStore();
const { isMobile } = useIsMobile();
// const userStore = useUserStore();
const activePageStore = useActivePageStore();
const notificationTopBarStore = useNotificationTopBarStore();

const isFilterDisplay = ref(false);
const activeTab = ref('all');
const tabList = [
  { value: 'all', label: 'playlistPage.all' },
  { value: 'mine', label: 'playlistPage.yours' },
  { value: 'teacher', label: 'playlistPage.teacher' }
];

const showCreateEditModal = ref(false);
// const modalMode = ref('create');

const isPlaylistDataLoading = ref(false);
const playlistsData = ref([]);
const teacherPlaylistsData = ref([]);
const myPlaylistData = ref([]);
const teachersListData = ref([])

const currentPage = ref(1);
const itemsPerPage = ref(12); // Default itemsPerPage 10
const totalItems = ref(0);

const showingFrom = computed(() => {
    return (currentPage.value - 1) * itemsPerPage.value + 1;
});

const showingTo = computed(() => {
    // If this is the last page, return the total items, else return the current page * items per page
    return totalItems.value < currentPage.value * itemsPerPage.value ? totalItems.value : currentPage.value * itemsPerPage.value;
});

const totalPages = computed(() => {
    return Math.ceil(totalItems.value / itemsPerPage.value);
});

const changePage = (page) => {
    currentPage.value = page;
    fetchAndCombineAllPlaylist();
};

const toggleFilter = () => {
    isFilterDisplay.value = !isFilterDisplay.value;
}

// const goToPlaylistDetailsPage = () => {
//     router.push({
//             name: 'Playlist Detail',
//             query: {
//                 id: 1
//             }
//         });
// }


watch(activeTab, (newValue) => {
    console.log('Active tab:', newValue);
    if (newValue === 'teacher') {
        fetchTeacherPlaylist().then(data => {
            playlistsData.value = data;
        });
        fetchTeachers();
    } else if (newValue === 'mine') {
        fetchMyPlaylist().then(data => {
            playlistsData.value = data;
        });
    } else {
        fetchAndCombineAllPlaylist();
    }
});

const openCreateEditPlaylistModal = () => {
    addToPlaylistStore.setModalMode('create');
    addToPlaylistStore.setRedirectToCreatePlaylistPage(true);
    addToPlaylistStore.setShowCreateOrEditPlaylistModal(true);
    // showCreateEditModal.value = true
}

const closeCreateEditPlaylistModal = () => {
    addToPlaylistStore.setShowCreateOrEditPlaylistModal(false);
    // showCreateEditModal.value = false
}

watch(showCreateEditModal, (newValue) => {
    if (newValue) {
        // Add 'overflow-hidden' class to the body when modal is shown
        document.body.classList.add('overflow-hidden');
    } else {
        // Remove 'overflow-hidden' class when modal is hidden
        document.body.classList.remove('overflow-hidden');
    }
});


const fetchMyPlaylist = (page = 1, isfetchAndCombineAllPlaylist = false) => {
    if (!isfetchAndCombineAllPlaylist){
        isPlaylistDataLoading.value = true;
    }
    // isPlaylistDataLoading.value = true;
    return axios.get(`api/v1/user-playlists?page=${page}`)
        .then((response) => {
            const userPlaylists = response.data.data.map(playlist => ({
                ...playlist,
                isTeacherPlaylist: false
            }));
            console.log('fetchMyPlaylist response', userPlaylists);
            myPlaylistData.value = userPlaylists;
            totalItems.value = response.data.total;
            currentPage.value = response.data.current_page;
            return userPlaylists;
        })
        .catch((error) => {
            console.log(error);
            return [];
        })
        .finally(() => {
            if (!isfetchAndCombineAllPlaylist){
                isPlaylistDataLoading.value = false;
            }
            // isPlaylistDataLoading.value = false;
        });
}

const fetchTeacherPlaylist = (page = 1, isfetchAndCombineAllPlaylist = false) => {
    if (!isfetchAndCombineAllPlaylist){
        isPlaylistDataLoading.value = true;
    }
    // isPlaylistDataLoading.value = true;
    return axios.get(`api/v1/playlists?page=${page}`)
        .then((response) => {
            isPlaylistDataLoading.value = true;
            const teacherPlaylists = response.data.data.map(playlist => ({
                ...playlist,
                isTeacherPlaylist: true
            }));
            console.log('Fetch teacher playlist:', teacherPlaylists);
            teacherPlaylistsData.value = teacherPlaylists;
            totalItems.value = response.data.total;
            currentPage.value = response.data.current_page;
            return teacherPlaylists;
        })
        .catch((error) => {
            console.error('Failed to fetch API data:', error);
            return [];
        })
        .finally(() => {
            if (!isfetchAndCombineAllPlaylist){
                isPlaylistDataLoading.value = false;
            }
            // isPlaylistDataLoading.value = false;
        });
}

// const isfetchAndCombineAllPlaylist = ref(false);
const fetchAndCombineAllPlaylist = (page = 1) => {
    isPlaylistDataLoading.value = true;
    console.log('Fetch all playlists');

    axios.all([fetchTeacherPlaylist(page, true), fetchMyPlaylist(page, true)])
        .then(axios.spread((teacherPlaylist, myPlaylist) => {
            playlistsData.value = [...teacherPlaylist, ...myPlaylist];
            console.log('Combined playlists:', playlistsData.value);
        }))
        .catch((error) => {
            console.error('Failed to fetch all playlists:', error);
        })
        .finally(() => {
            isPlaylistDataLoading.value = false;
        });
}

const refreshGetPlaylistsApi = () => {
    if (activeTab.value === 'teacher') {
        console.log('Refresh teacher playlist');
        fetchTeacherPlaylist(currentPage.value, false).then(data => {
            playlistsData.value = data;
        });
        fetchTeachers();
    } else if (activeTab.value === 'mine') {
        fetchMyPlaylist(currentPage.value, false).then(data => {
            playlistsData.value = data;
        });
    } else {
        console.log('Refresh all playlists');
        fetchAndCombineAllPlaylist(currentPage.value);
    }
}

const isTeachersListDataLoading = ref(false);
const fetchTeachers = () => {
    isTeachersListDataLoading.value = true;
    axios.get('api/v1/users?role=cikgu')
    .then(response => {
        console.log('Teachers data:', response.data.data)
        teachersListData.value = response.data.data;
    }).catch(error => {
        console.error('Failed to fetch teachers data:', error);
    }).finally(() => {
        isTeachersListDataLoading.value = false
    });
}

onMounted(() => {
    activePageStore.setSelectedActivePage(6)
    isPlaylistDataLoading.value = true;
    fetchAndCombineAllPlaylist();
});
</script>

<template>
    <div class="min-h-screen">
        <NotificationTopBar v-show="notificationTopBarStore.isShowNotificationTopBar" />
        <CreateEditPlaylistModal v-show="addToPlaylistStore.showCreateOrEditPlaylistModal" @close-create-edit-playlist-modal="closeCreateEditPlaylistModal" :isAtPlaylistPages="true"/>
        <page-navigator from="Laman Utama" to="Playlist" />
        <div :class="{'text-black': modeStore.selectedMode === 'light', ' text-white': modeStore.selectedMode === 'dark'}" class="flex justify-between justify-items-center my-auto py-4 px-[30px]">
            <h2 :class="{'mobile-title': isMobile, 'page-top-title': !isMobile}">Playlist</h2>
        </div>
        <section 
            class="flex flex-col gap-8 rounded-xl p-[30px] bg-transparent"
            :class="{'bg-white': modeStore.selectedMode === 'light', 'bg-custom-blue-gray text-white': modeStore.selectedMode === 'dark'}"
        >
            <div class="flex gap-2 justify-between">
                <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('playlistPage.playlist')}}</h2>
                <div class="my-auto h-10">
                    <div class="flex gap-1 justify-between h-10 my-auto">
                        <div @click="toggleFilter()" class="hidden w-8 my-auto cursor-pointer">
                            <img :src="modeStore.selectedMode === 'dark' ? whiteFilterIcon : blackFilterIcon" alt="search icon" />
                        </div>
                        <div class="my-auto">
                            <div @click="openCreateEditPlaylistModal()" :class="{ 'bg-theme-green': themeStore.selectedTheme === 'green', 'bg-theme-orange': themeStore.selectedTheme === 'orange', 'bg-theme-blue': themeStore.selectedTheme === 'blue', 'bg-theme-purple': themeStore.selectedTheme === 'purple', 'bg-theme-darkpink': themeStore.selectedTheme === 'darkpink'}" class="w-full py-2 px-4 rounded-md text-white cursor-pointer"><span class="text-xl">+</span> {{ $t('playlistPage.createPlaylist')}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-between justify-items-center my-auto">
                <div class="flex gap-2">
                    <TabList
                        v-model:activeTab="activeTab"
                        :tabs="tabList"
                    />
                </div>
                <!-- <div class="my-auto h-10">
                    <div class="flex gap-1 justify-between h-10 my-auto">
                        <div class="w-8 my-auto cursor-pointer">
                            <img :src="modeStore.selectedMode === 'dark' ? whiteInfoCircle : blackInfoCircle" alt="info circle icon" />
                        </div>
                        <div @click="toggleFilter()" class="w-8 my-auto cursor-pointer">
                            <img :src="modeStore.selectedMode === 'dark' ? whiteFilterIcon : blackFilterIcon" alt="search icon" />
                        </div>
                        <div class="my-auto">
                            <div @click="openCreateEditPlaylistModal()"  class="w-full py-2 px-4 rounded-md bg-gray-800 text-white cursor-pointer"><span class="text-xl">+</span> Cipta Playlist</div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div v-if="activeTab === 'teacher'">
                <div 
                    class="flex items-center mb-4 gap-2"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('title.teacherList')}}</h2>
                    <router-link to="/teachers" class="title-action"><i class="fa-solid fa-chevron-right fa-lg px-2 my-auto"></i></router-link>
                </div>
                <div v-show="isTeachersListDataLoading" class="flex items-center justify-center w-full gap-2">
                    <div>{{ $t('general.loading')}}</div>
                    <span class="loading loading-bars loading-sm"></span>
                </div>
                <div v-show="!isTeachersListDataLoading" 
                    class="grid gap-x-4 gap-y-12 md:grid-cols-7 md:gap-4 justify-items-center"
                    :class="{'avatar-mobile-container' : isMobile}"
                >
                    <TeachersCard
                        class="md:min-w-0 lg:w-[80%]"
                        v-for="teacher in teachersListData"
                        :key="teacher.id"
                        :image="teacher.avatar_url"
                        :name="teacher.name"
                        :role="teacher.role_name"
                        :id="teacher.id"
                    />
                </div>
            </div>
            <!-- <div v-show="isPlaylistDataLoading" class="flex items-center justify-center w-full gap-2">
                <div>{{ $t('general.loading')}}</div>
                <span class="loading loading-bars loading-sm"></span>
            </div> -->

            <div v-show="isPlaylistDataLoading" class="md:grid md:grid-cols-3 justify-items-center gap-4">
                <PlaylistCardSkeletonLoader :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                <PlaylistCardSkeletonLoader :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/> 
                <PlaylistCardSkeletonLoader :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
            </div>

            <div v-show="!isPlaylistDataLoading" class="md:grid md:grid-cols-3 justify-items-center gap-4">
                <PlaylistCardForTeacher
                    class="min-w-full md:min-w-0 lg:w-full"
                    v-for="list in playlistsData"
                        :key="list.id"
                        :playlistId="list.id"
                        :playlistThumbnail="list.playlist_thumbnail"
                        :assetBroadcastThumbnails="list.asset_broadcast_thumbnails.length >= 3 ? list.asset_broadcast_thumbnails: null"
                        :subject="list.title"
                        :documentCount="list.document_count"
                        :interactionCount="list.interactive_count"
                        :videoCount="list.video_count"
                        :isAddedToWorkspace="list.has_added_to_workspace"
                        :isTeacherPlaylist="list.isTeacherPlaylist"
                        @refresh-teacher-playlist-api="refreshGetPlaylistsApi"
                />
            </div>

            <!-- Pagination -->
            <div class="hidden lg:block">
                <CustomPagination
                    :showingFrom="showingFrom"
                    :showingTo="showingTo"
                    :totalItems="totalItems"
                    :totalPages="totalPages"
                    :currentPage="currentPage"
                    :itemsPerPage="itemsPerPage"
                    @change-page="changePage"
                />
            </div>
        </section>
    </div>
</template>