<template>
    <div class="player-controls">
      <div class="control-item" @click="rewind">
        <!-- Rewind 15sec icon SVG code -->

        <svg v-if="modeStore.selectedMode === 'light'" width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4175 11.1044V18H9.16835V12.3199H9.12795L7.51515 13.3502V12.2054L9.22896 11.1044H10.4175ZM14.5657 18.0943C14.1167 18.0943 13.7149 18.0101 13.3603 17.8418C13.0056 17.6712 12.7239 17.4377 12.5152 17.1414C12.3086 16.8451 12.1987 16.5062 12.1852 16.1246H13.3973C13.4198 16.4074 13.5421 16.6386 13.7643 16.8182C13.9865 16.9955 14.2536 17.0842 14.5657 17.0842C14.8103 17.0842 15.0281 17.0281 15.2189 16.9158C15.4097 16.8036 15.56 16.6476 15.67 16.4478C15.78 16.248 15.8339 16.0202 15.8316 15.7643C15.8339 15.5039 15.7789 15.2727 15.6667 15.0707C15.5544 14.8687 15.4007 14.7104 15.2054 14.596C15.0101 14.4792 14.7856 14.4209 14.532 14.4209C14.3255 14.4186 14.1223 14.4568 13.9226 14.5354C13.7228 14.6139 13.5645 14.7172 13.4478 14.8451L12.3199 14.6599L12.6801 11.1044H16.6801V12.1481H13.7138L13.5152 13.9764H13.5556C13.6835 13.826 13.8642 13.7015 14.0976 13.6027C14.3311 13.5017 14.587 13.4512 14.8653 13.4512C15.2828 13.4512 15.6554 13.5499 15.9832 13.7475C16.3109 13.9428 16.569 14.2121 16.7576 14.5556C16.9461 14.899 17.0404 15.2918 17.0404 15.734C17.0404 16.1897 16.9349 16.596 16.7239 16.9529C16.5152 17.3075 16.2245 17.587 15.8519 17.7912C15.4815 17.9933 15.0527 18.0943 14.5657 18.0943Z" fill="black"/>
            <path d="M10.4375 5.1562L12.5 2.58331" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.11425 8.625C3.958 10.1667 3.23926 12.0729 3.23926 14.1562C3.23926 19.2708 7.38511 23.4167 12.4997 23.4167C17.6143 23.4167 21.7601 19.2708 21.7601 14.1562C21.7601 9.04166 17.6143 4.89581 12.4997 4.89581C11.7914 4.89581 11.1039 4.98962 10.4372 5.14587" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <svg v-else width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4175 11.1044V18H9.16835V12.3199H9.12795L7.51515 13.3502V12.2054L9.22896 11.1044H10.4175ZM14.5657 18.0943C14.1167 18.0943 13.7149 18.0101 13.3603 17.8418C13.0056 17.6712 12.7239 17.4377 12.5152 17.1414C12.3086 16.8451 12.1987 16.5062 12.1852 16.1246H13.3973C13.4198 16.4074 13.5421 16.6386 13.7643 16.8182C13.9865 16.9955 14.2536 17.0842 14.5657 17.0842C14.8103 17.0842 15.0281 17.0281 15.2189 16.9158C15.4097 16.8036 15.56 16.6476 15.67 16.4478C15.78 16.248 15.8339 16.0202 15.8316 15.7643C15.8339 15.5039 15.7789 15.2727 15.6667 15.0707C15.5544 14.8687 15.4007 14.7104 15.2054 14.596C15.0101 14.4792 14.7856 14.4209 14.532 14.4209C14.3255 14.4186 14.1223 14.4568 13.9226 14.5354C13.7228 14.6139 13.5645 14.7172 13.4478 14.8451L12.3199 14.6599L12.6801 11.1044H16.6801V12.1481H13.7138L13.5152 13.9764H13.5556C13.6835 13.826 13.8642 13.7015 14.0976 13.6027C14.3311 13.5017 14.587 13.4512 14.8653 13.4512C15.2828 13.4512 15.6554 13.5499 15.9832 13.7475C16.3109 13.9428 16.569 14.2121 16.7576 14.5556C16.9461 14.899 17.0404 15.2918 17.0404 15.734C17.0404 16.1897 16.9349 16.596 16.7239 16.9529C16.5152 17.3075 16.2245 17.587 15.8519 17.7912C15.4815 17.9933 15.0527 18.0943 14.5657 18.0943Z" fill="white"/>
            <path d="M10.4375 5.15589L12.5 2.58301" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.11327 8.62469C3.95702 10.1664 3.23828 12.0726 3.23828 14.1559C3.23828 19.2705 7.38413 23.4164 12.4987 23.4164C17.6133 23.4164 21.7591 19.2705 21.7591 14.1559C21.7591 9.04136 17.6133 4.89551 12.4987 4.89551C11.7904 4.89551 11.1029 4.98931 10.4362 5.14556" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

      </div>
      <div class="control-item" @click="skipPrev" :class="{'opacity-10': !props.prevUrl}">
        <!-- Skip prev icon SVG code -->
        <svg v-if="modeStore.selectedMode === 'light'" width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.9167 9.18752V16.8125C22.9167 18.375 21.2188 19.3542 19.8646 18.5729L16.5625 16.6771L13.2604 14.7709C13.0521 14.6459 12.8854 14.5104 12.75 14.3438V11.6771C12.8854 11.5105 13.0521 11.375 13.2604 11.25L16.5625 9.34376L19.8646 7.44795C21.2188 6.64587 22.9167 7.62502 22.9167 9.18752Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.75 9.18752V16.8125C12.75 18.375 11.0521 19.3542 9.69791 18.5729L6.39585 16.6771L3.09375 14.7709C1.73958 13.9896 1.73958 12.0417 3.09375 11.25L6.39585 9.34376L9.69791 7.44795C11.0521 6.64587 12.75 7.62502 12.75 9.18752Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg v-else width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.9167 9.18807V16.8131C22.9167 18.3756 21.2188 19.3547 19.8646 18.5735L16.5625 16.6777L13.2604 14.7714C13.0521 14.6464 12.8854 14.511 12.75 14.3443V11.6777C12.8854 11.511 13.0521 11.3756 13.2604 11.2506L16.5625 9.34431L19.8646 7.4485C21.2188 6.64642 22.9167 7.62557 22.9167 9.18807Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.75 9.18807V16.8131C12.75 18.3756 11.0521 19.3547 9.69791 18.5735L6.39585 16.6777L3.09375 14.7714C1.73958 13.9902 1.73958 12.0422 3.09375 11.2506L6.39585 9.34431L9.69791 7.4485C11.0521 6.64642 12.75 7.62557 12.75 9.18807Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="control-item" @click="togglePlay">
        <!-- Play icon SVG code -->
        <svg v-if="!isPlaying && modeStore.selectedMode === 'light'" class="hover:opacity-90 transition-all" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.9987 3.33325C10.7987 3.33325 3.33203 10.7999 3.33203 19.9999C3.33203 29.1999 10.7987 36.6666 19.9987 36.6666C29.1987 36.6666 36.6654 29.1999 36.6654 19.9999C36.6654 10.7999 29.1987 3.33325 19.9987 3.33325ZM24.432 22.8833L22.2987 24.1166L20.1654 25.3499C17.4154 26.9333 15.1654 25.6333 15.1654 22.4666V19.9999V17.5333C15.1654 14.3499 17.4154 13.0666 20.1654 14.6499L22.2987 15.8833L24.432 17.1166C27.182 18.6999 27.182 21.2999 24.432 22.8833Z" fill="#F97316"/>
        </svg>
        <svg v-if="!isPlaying && modeStore.selectedMode !== 'light'" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0026 3.33203C10.8026 3.33203 3.33594 10.7987 3.33594 19.9987C3.33594 29.1987 10.8026 36.6654 20.0026 36.6654C29.2026 36.6654 36.6693 29.1987 36.6693 19.9987C36.6693 10.7987 29.2026 3.33203 20.0026 3.33203ZM24.4359 22.882L22.3026 24.1154L20.1693 25.3487C17.4193 26.932 15.1693 25.632 15.1693 22.4654V19.9987V17.532C15.1693 14.3487 17.4193 13.0654 20.1693 14.6487L22.3026 15.882L24.4359 17.1154C27.1859 18.6987 27.1859 21.2987 24.4359 22.882Z" fill="white"/>
        </svg>

        <!-- Pause icon SVG code -->
        <svg v-if="isPlaying && modeStore.selectedMode === 'light'" width="40" class="hover:opacity-90 transition-all" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.9499 3.33337C10.7499 3.33337 3.2832 10.8 3.2832 20C3.2832 29.2 10.7499 36.6667 19.9499 36.6667C29.1499 36.6667 36.6165 29.2 36.6165 20C36.6165 10.8 29.1665 3.33337 19.9499 3.33337ZM17.8665 25.05C17.8665 25.85 17.5332 26.1667 16.6832 26.1667H14.5165C13.6665 26.1667 13.3332 25.85 13.3332 25.05V14.95C13.3332 14.15 13.6665 13.8334 14.5165 13.8334H16.6665C17.5165 13.8334 17.8499 14.15 17.8499 14.95V25.05H17.8665ZM26.6665 25.05C26.6665 25.85 26.3332 26.1667 25.4832 26.1667H23.3332C22.4832 26.1667 22.1499 25.85 22.1499 25.05V14.95C22.1499 14.15 22.4832 13.8334 23.3332 13.8334H25.4832C26.3332 13.8334 26.6665 14.15 26.6665 14.95V25.05Z" fill="#F97316"/>
        </svg>
        <svg v-if="isPlaying && modeStore.selectedMode !== 'light'" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.9499 3.33337C10.7499 3.33337 3.2832 10.8 3.2832 20C3.2832 29.2 10.7499 36.6667 19.9499 36.6667C29.1499 36.6667 36.6165 29.2 36.6165 20C36.6165 10.8 29.1665 3.33337 19.9499 3.33337ZM17.8665 25.05C17.8665 25.85 17.5332 26.1667 16.6832 26.1667H14.5165C13.6665 26.1667 13.3332 25.85 13.3332 25.05V14.95C13.3332 14.15 13.6665 13.8334 14.5165 13.8334H16.6665C17.5165 13.8334 17.8499 14.15 17.8499 14.95V25.05H17.8665ZM26.6665 25.05C26.6665 25.85 26.3332 26.1667 25.4832 26.1667H23.3332C22.4832 26.1667 22.1499 25.85 22.1499 25.05V14.95C22.1499 14.15 22.4832 13.8334 23.3332 13.8334H25.4832C26.3332 13.8334 26.6665 14.15 26.6665 14.95V25.05Z" fill="white"/>
        </svg>
      </div>
      <div class="control-item" @click="skipNext" :class="{'opacity-10': !props.nextUrl}">
        <!-- Skip next icon SVG code -->
        <svg v-if="modeStore.selectedMode === 'light'" width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.08203 9.18752V16.8125C2.08203 18.375 3.77994 19.3542 5.13411 18.5729L8.4362 16.6771L11.7383 14.7709C11.9466 14.6459 12.1133 14.5104 12.2487 14.3438V11.6771C12.1133 11.5105 11.9466 11.375 11.7383 11.25L8.4362 9.34376L5.13411 7.44795C3.77994 6.64587 2.08203 7.62502 2.08203 9.18752Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.25 9.18752V16.8125C12.25 18.375 13.9479 19.3542 15.3021 18.5729L18.6041 16.6771L21.9062 14.7709C23.2604 13.9896 23.2604 12.0417 21.9062 11.25L18.6041 9.34376L15.3021 7.44795C13.9479 6.64587 12.25 7.62502 12.25 9.18752Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg v-else width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.08203 9.18807V16.8131C2.08203 18.3756 3.77994 19.3547 5.13411 18.5735L8.4362 16.6777L11.7383 14.7714C11.9466 14.6464 12.1133 14.511 12.2487 14.3443V11.6777C12.1133 11.511 11.9466 11.3756 11.7383 11.2506L8.4362 9.34431L5.13411 7.4485C3.77994 6.64642 2.08203 7.62557 2.08203 9.18807Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.25 9.18807V16.8131C12.25 18.3756 13.9479 19.3547 15.3021 18.5735L18.6041 16.6777L21.9062 14.7714C23.2604 13.9902 23.2604 12.0422 21.9062 11.2506L18.6041 9.34431L15.3021 7.4485C13.9479 6.64642 12.25 7.62557 12.25 9.18807Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="control-item" @click="forward">
        <!-- Forward 15sec icon SVG code -->
        <svg v-if="modeStore.selectedMode === 'light'" width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4175 12.1044V19H9.16835V13.3199H9.12795L7.51515 14.3502V13.2054L9.22896 12.1044H10.4175ZM14.5657 19.0943C14.1167 19.0943 13.7149 19.0101 13.3603 18.8418C13.0056 18.6712 12.7239 18.4377 12.5152 18.1414C12.3086 17.8451 12.1987 17.5062 12.1852 17.1246H13.3973C13.4198 17.4074 13.5421 17.6386 13.7643 17.8182C13.9865 17.9955 14.2536 18.0842 14.5657 18.0842C14.8103 18.0842 15.0281 18.0281 15.2189 17.9158C15.4097 17.8036 15.56 17.6476 15.67 17.4478C15.78 17.248 15.8339 17.0202 15.8316 16.7643C15.8339 16.5039 15.7789 16.2727 15.6667 16.0707C15.5544 15.8687 15.4007 15.7104 15.2054 15.596C15.0101 15.4792 14.7856 15.4209 14.532 15.4209C14.3255 15.4186 14.1223 15.4568 13.9226 15.5354C13.7228 15.6139 13.5645 15.7172 13.4478 15.8451L12.3199 15.6599L12.6801 12.1044H16.6801V13.1481H13.7138L13.5152 14.9764H13.5556C13.6835 14.826 13.8642 14.7015 14.0976 14.6027C14.3311 14.5017 14.587 14.4512 14.8653 14.4512C15.2828 14.4512 15.6554 14.5499 15.9832 14.7475C16.3109 14.9428 16.569 15.2121 16.7576 15.5556C16.9461 15.899 17.0404 16.2918 17.0404 16.734C17.0404 17.1897 16.9349 17.596 16.7239 17.9529C16.5152 18.3075 16.2245 18.587 15.8519 18.7912C15.4815 18.9933 15.0527 19.0943 14.5657 19.0943Z" fill="black"/>
            <path d="M14.5625 6.19789L12.5 3.625" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.8848 9.66668C21.041 11.2084 21.7598 13.1146 21.7598 15.1979C21.7598 20.3125 17.6139 24.4584 12.4993 24.4584C7.38475 24.4584 3.2389 20.3125 3.2389 15.1979C3.2389 10.0833 7.38475 5.9375 12.4993 5.9375C13.2077 5.9375 13.8951 6.0313 14.5618 6.18755" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg v-else width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4175 12.1044V19H9.16835V13.3199H9.12795L7.51515 14.3502V13.2054L9.22896 12.1044H10.4175ZM14.5657 19.0943C14.1167 19.0943 13.7149 19.0101 13.3603 18.8418C13.0056 18.6712 12.7239 18.4377 12.5152 18.1414C12.3086 17.8451 12.1987 17.5062 12.1852 17.1246H13.3973C13.4198 17.4074 13.5421 17.6386 13.7643 17.8182C13.9865 17.9955 14.2536 18.0842 14.5657 18.0842C14.8103 18.0842 15.0281 18.0281 15.2189 17.9158C15.4097 17.8036 15.56 17.6476 15.67 17.4478C15.78 17.248 15.8339 17.0202 15.8316 16.7643C15.8339 16.5039 15.7789 16.2727 15.6667 16.0707C15.5544 15.8687 15.4007 15.7104 15.2054 15.596C15.0101 15.4792 14.7856 15.4209 14.532 15.4209C14.3255 15.4186 14.1223 15.4568 13.9226 15.5354C13.7228 15.6139 13.5645 15.7172 13.4478 15.8451L12.3199 15.6599L12.6801 12.1044H16.6801V13.1481H13.7138L13.5152 14.9764H13.5556C13.6835 14.826 13.8642 14.7015 14.0976 14.6027C14.3311 14.5017 14.587 14.4512 14.8653 14.4512C15.2828 14.4512 15.6554 14.5499 15.9832 14.7475C16.3109 14.9428 16.569 15.2121 16.7576 15.5556C16.9461 15.899 17.0404 16.2918 17.0404 16.734C17.0404 17.1897 16.9349 17.596 16.7239 17.9529C16.5152 18.3075 16.2245 18.587 15.8519 18.7912C15.4815 18.9933 15.0527 19.0943 14.5657 19.0943Z" fill="white"/>
            <path d="M14.5625 6.19789L12.5 3.625" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.8848 9.66668C21.041 11.2084 21.7598 13.1146 21.7598 15.1979C21.7598 20.3125 17.6139 24.4584 12.4993 24.4584C7.38475 24.4584 3.2389 20.3125 3.2389 15.1979C3.2389 10.0833 7.38475 5.9375 12.4993 5.9375C13.2077 5.9375 13.8951 6.0313 14.5618 6.18755" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </template>
  
  <script setup>
  import { defineEmits, defineProps, computed } from 'vue';
  import { useModeStore } from '@/store/mode';
  const emit = defineEmits(['rewind', 'skip-prev', 'toggle-play', 'skip-next', 'forward']);
  const modeStore = useModeStore();

  // define props for next or prev url
    const props = defineProps({
        currentPlaying: {
            type: Boolean,
            required: true,
            default: false,
        },
        nextUrl: String,
        prevUrl: String,
    });
    

  const isPlaying = computed(() => props.currentPlaying);
  
  const rewind = () => {
    emit('rewind');
  };
  
  const skipPrev = () => {
    if (!props.prevUrl) {
        return;
    }
    emit('skip-prev');
  };
  
  const togglePlay = () => {
    isPlaying.value = !isPlaying.value;
    emit('toggle-play');
  };
  
  const skipNext = () => {
    if (!props.nextUrl) {
        return;
    }
    emit('skip-next');
  };
  
  const forward = () => {
    emit('forward');
  };
  </script>
  
  <style scoped>
  .player-controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .control-item {
    margin: 0 10px;
    cursor: pointer;
  }
  </style>