import { defineStore } from 'pinia'

export const useWorkspaceStore = defineStore('workspace', {
    state: () => {
      return {
        action: null, //add or remove
        playlistId: null,
        assetBroadcastId: null,
      }
    },
    actions: {
        setAction(action) {
            this.action = action
        },
        setPlaylistId(playlistId) {
            this.playlistId = playlistId
        },
        setAssetBroadcastId(assetBroadcastId) {
            this.assetBroadcastId = assetBroadcastId
        },
    },
  })