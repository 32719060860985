<script setup>
// import { ref, onMounted } from 'vue'
import { onMounted } from 'vue'
import { useActivePageStore } from '../store/activePageTab.js';

const activePageStore = useActivePageStore();

onMounted(() => {
    activePageStore.setSelectedActivePage(7)
});
</script>

<template>
    <div>
    </div>
</template>