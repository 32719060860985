<script setup>
import { ref, watch, computed, onMounted, defineProps } from 'vue';
import axios from 'axios';
import router from '../router/index.js';

import { useModeStore } from '../store/mode.js';
import { useThemeStore } from '../store/theme.js';
import { getModeStrokeColor, getContentTypeLabel } from '../utils/commonFunctions.js';
import { useUserStore } from '../store/user.js'; 
import { useActivePageStore } from '../store/activePageTab.js';
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';

import PageNavigator from '../components/PageNavigator.vue';
import CustomPagination from '../components/CustomPagination.vue';
import CreateEditPlaylistModal from '../components/CreateEditPlaylistModal.vue';

import blackFilterIcon from '../assets/icons/black_filter_icon.svg';
import whiteFilterIcon from '../assets/icons/white_filter_icon.svg';

import playlistImg from '../assets/blank_white_img.png';
import darkplaylistImg from '../assets/blank_dark_img.png';
// import AzindaFaizal from '../assets/Images/Components/TeachersAvatar/aznida-faizal.png';
// import PravinKanna from '../assets/Images/Components/TeachersAvatar/pravin-kanna.png';
// import AuthUserAvatar from '../assets/icons/avatar.png';
// import MesinKompleks from '../assets/Images/Components/DocumentsCard/document-card-1.png';
// import HaiwanVertebrata from '../assets/Images/Components/DocumentsCard/document-card-2.png';

const props = defineProps({
    id: Number
});

const addToPlaylistStore = useAddToPlaylistStore();
const modeStore = useModeStore();
const themeStore = useThemeStore();
console.log('themeStore', themeStore);
const modeStrokeColor = getModeStrokeColor();
// const hasPlaylist = ref(false);
const showCreateEditModal = ref(false);
const searchQuery = ref('');
// const modalMode = ref('edit');
const userStore = useUserStore();
const activePageStore = useActivePageStore();

const currentPage = ref(1);
const itemsPerPage = ref(1); // Default itemsPerPage 10
const totalItems = ref(1);

const showingFrom = ref(1);

const showingTo = ref(1);

const totalPages = ref(1);

const changePage = (page) => {
    currentPage.value = page;
    isAssetBroadcastListLoading.value = true;
    fetchAssetBroadcastList(page);
};

// const openCreateEditPlaylistModal = () => {
//     showCreateEditModal.value = true
// }

const closeCreateEditPlaylistModal = () => {
    // showCreateEditModal.value = false
    addToPlaylistStore.setShowCreateOrEditPlaylistModal(false)
    addToPlaylistStore.setSelectedPlaylistIds([])
}

const editPlaylist = () => {
    addToPlaylistStore.setModalMode('edit')
    addToPlaylistStore.setShowCreateOrEditPlaylistModal(true)

    // modalMode.value = 'edit'
    // openCreateEditPlaylistModal()
}

watch(showCreateEditModal, (newValue) => {
    if (newValue) {
        // Add 'overflow-hidden' class to the body when modal is shown
        document.body.classList.add('overflow-hidden');
    } else {
        // Remove 'overflow-hidden' class when modal is hidden
        document.body.classList.remove('overflow-hidden');
    }
});

const playlistData = ref(
    {
        playlist_thumbnail: playlistImg,
    }
)

const playlistThumbnail = computed(() => {
    // return playlistData.value.playlist_thumbnail ? playlistData.value.playlist_thumbnail : playlistImg;
    if (playlistData.value ) {
        if (playlistData.value.playlist_thumbnail) {
            return playlistData.value.playlist_thumbnail;
        } else {
            return modeStore.selectedMode === 'dark' ? darkplaylistImg : playlistImg;
        }
    } else {
        return modeStore.selectedMode === 'dark' ? darkplaylistImg : playlistImg;
    }
})

const assetBroadcastList = ref([]);
const isAssetBroadcastListLoading = ref(false);
function fetchAssetBroadcastList(page = 1) {
    isAssetBroadcastListLoading.value = true;
    axios.get(`api/v1/asset-broadcasts?page=${page}`).then(response => {
        console.log('fetchAssetBroadcastList response', response.data);
        assetBroadcastList.value = response.data.data;
        totalItems.value = response.data.total;
        currentPage.value = response.data.current_page;
        showingFrom.value = response.data.from;
        showingTo.value = response.data.to;
        totalPages.value = response.data.last_page
        console.log(totalPages.value);
    }).catch(error => {
        console.error('Failed to fetch playlists data:', error);
    }).finally(() => {
        isAssetBroadcastListLoading.value = false
    });
}

const assetBroadcastIdsInPlaylist = ref([]);
const fetchPlaylistData = () => {
    const playlistId = props.id;
    console.log('Fetching playlist data id', playlistId);
    axios.get(`api/v1/user-playlists/${playlistId}`)
    .then((response) => {
        console.log('fetchPlaylistData response', response.data);
        playlistData.value = response.data;
        assetBroadcastIdsInPlaylist.value = response.data.asset_broadcasts_ids;
    })
    .catch((error) => {
        console.log(error);
    });

}

const goToDocument = (id) => {
    router.push({ name: 'DocumentDetailed', params: { id: id } });
}

// const addToPlaylist = (id) => {
//     console.log('Add to playlist', id);
//     axios.post(`api/v1/user-playlists/${props.id}/asset-broadcasts`, {
//         asset_broadcast_id: id,
//     })
//     .then((response) => {
//         console.log('addToPlaylist response =====', response);
//         fetchAssetBroadcastList();
//     })
//     .catch((error) => {
//         console.log(error);
//     })
// }

let isCallingApi = false;

const addToPlaylist = (id) => {
    if (isCallingApi) return;

    isCallingApi = true;

    console.log('Add to playlist', id);
    axios.post(`api/v1/user-playlists/${props.id}/single-asset-broadcasts`, {
        asset_broadcast_id: id,
    })
    .then((response) => {
        console.log('addToPlaylist response =====', response);
        fetchAssetBroadcastList();
        fetchPlaylistData();
    })
    .catch((error) => {
        console.log(error);
    })
    .finally(() => {
        isCallingApi = false;
    });
};

const isAddedToPlaylist = (id) => {
    return assetBroadcastIdsInPlaylist.value.includes(id);
};

const removeFromPlaylist = (assetBroadcastId) => {
    // Remove asset broadcast from playlist
    axios.delete(`api/v1/user-playlists/${props.id}/asset-broadcasts/${assetBroadcastId}`)
        .then((response) => {
            console.log('removeFromPlaylist result.data:', response.data);
            fetchAssetBroadcastList();
            fetchPlaylistData();
        })
        .catch((error) => {
            console.error('Failed to remove asset broadcast:', error);
        });
}

const performSearch = () => {
    isAssetBroadcastListLoading.value = true;
    if (searchQuery.value.trim() !== '') {
        axios.get('api/v1/asset-broadcasts', {
            params: {
                search: searchQuery.value
            }
        })
        .then((response) => {
        assetBroadcastList.value = response.data.data;
        })
        .catch((error) => {
            console.log(error);
            assetBroadcastList.value = [];
        })
        .finally(() => {
            isAssetBroadcastListLoading.value = false;
        });
    } else {
        fetchAssetBroadcastList();
    }
};

onMounted(() => {
    document.body.classList.remove('overflow-hidden');
    activePageStore.setSelectedActivePage(6)
    // console.log('PlaylistCreate.vue mounted', props.id)
    fetchPlaylistData();
    fetchAssetBroadcastList();
})

</script>

<template>
    <div>
        <CreateEditPlaylistModal v-show="addToPlaylistStore.showCreateOrEditPlaylistModal" :playlistId=props.id @close-create-edit-playlist-modal="closeCreateEditPlaylistModal" @refresh-playlist-data="fetchPlaylistData" :isAtPlaylistPages="true"/>
        <page-navigator from="Playlist" to="Cipta Playlist" />

        <div  class="flex gap-2 px-6 py-4 my-4 rounded-xl relative overflow-hidden" style="backdrop-filter: blur(8px); border: 0px solid rgba(255, 255, 255, 0.2); box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);">
            <img :src="playlistThumbnail" alt="playlist image" class="absolute inset-0 w-full h-full object-cover filter blur-sm" />
            <div class="px-4 my-auto relative z-10 flex">
                <div class="relative">
                    <div v-if="playlistData.playlist_thumbnail">
                        <img :src="playlistData.playlist_thumbnail" class="h-20 md:h-60 shadow-lg" alt="playlist image" />
                    </div>
                    <div v-else>
                        <div :class="modeStore.selectedMode === 'light'? 'bg-gray-400': 'bg-[black]'" class="h-20 md:h-60 w-60 rounded-xl"></div>
                    </div>
                    <svg @click="editPlaylist()" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-10 md:h-16 cursor-pointer" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M36.667 6.66602H30.0003C13.3337 6.66602 6.66699 13.3327 6.66699 29.9994V49.9994C6.66699 66.666 13.3337 73.3327 30.0003 73.3327H50.0003C66.667 73.3327 73.3337 66.666 73.3337 49.9994V43.3327" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M53.4663 10.0675L27.1996 36.3342C26.1996 37.3342 25.1996 39.3008 24.9996 40.7342L23.5663 50.7675C23.0329 54.4008 25.5996 56.9342 29.2329 56.4342L39.2663 55.0008C40.6663 54.8008 42.6329 53.8008 43.6663 52.8008L69.9329 26.5342C74.4663 22.0008 76.5996 16.7342 69.9329 10.0675C63.2663 3.40084 57.9996 5.53418 53.4663 10.0675Z" stroke="white" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M49.7002 13.834C51.9335 21.8007 58.1669 28.034 66.1669 30.3007" stroke="white" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <!-- <img @click="editPlaylist()" src="../assets/icons/edit_image_icon_white.png" alt="Edit Icon" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-10 md:h-16 cursor-pointer"> -->
                </div>
                <div v-if="playlistData.playlist_thumbnail" class="px-4 my-auto text-white">
                    <h1 class="text-xl md:text-5xl font-bold py-2">{{ playlistData.title }}</h1>
                    <div class="flex gap-2">
                        <div class="max-w-6">
                            <img :src="userStore.user.avatar_url" alt="created by avatar" />
                        </div>
                        <p>{{ $t('general.by') }} {{ userStore.user.name }}</p>
                    </div>
                </div>
                <div v-else :class="modeStore.selectedMode === 'light'? 'text-black': 'text-white'" class="px-4 my-auto">
                    <h1 class="text-xl md:text-5xl font-bold py-2">{{ playlistData.title }}</h1>
                    <div class="flex gap-2">
                        <div class="max-w-6">
                            <img :src="userStore.user.avatar_url" alt="created by avatar" />
                        </div>
                        <p>{{ $t('general.by') }} {{ userStore.user.name }}</p>
                    </div>
                </div>
            </div>
        </div>

        <section 
            class="flex flex-col gap-8 rounded-xl p-[30px]"
            :class="{'bg-white': modeStore.selectedMode === 'light', 'bg-custom-blue-gray text-white': modeStore.selectedMode === 'dark'}"
        >
            <div class="flex justify-between">
                <div class="flex gap-4 h-12">
                    <div :class="modeStore.selectedMode === 'dark' ? 'border-gray-500' : 'border-black'" class="flex justify-between border rounded-lg cursor-pointer">
                        <input type="text" placeholder="Carian" class="px-4 py-2 bg-transparent focus:outline-none cursor-pointer" v-model="searchQuery" @input="performSearch">
                        <svg class="mr-4 my-auto cursor-pointer" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5 21.5C16.7467 21.5 21 17.2467 21 12C21 6.75329 16.7467 2.5 11.5 2.5C6.25329 2.5 2 6.75329 2 12C2 17.2467 6.25329 21.5 11.5 21.5Z" :stroke="modeStrokeColor" stroke-width="1.61" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22 22.5L20 20.5" :stroke="modeStrokeColor" stroke-width="1.61" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    <!-- <img v-if="modeStore.selectedMode === 'dark'" src="../assets/icons/search.png" alt="search icon" class=" right-0 top-0 bottom-0 m-auto h-4 pr-4 cursor-pointer">
                    <img v-else src="../assets/icons/search_black.png" alt="search icon" class=" right-0 top-0 bottom-0 m-auto h-4 pr-4 cursor-pointer"> -->
                    </div>
                </div>
                <div class="my-auto h-10">
                    <div class="flex gap-1 justify-between h-10 my-auto">
                        <div @click="toggleFilter()" class="hidden w-8 my-auto cursor-pointer">
                            <img :src="modeStore.selectedMode === 'dark' ? whiteFilterIcon : blackFilterIcon" alt="search icon" />
                        </div>
                    </div>
                </div>
            </div>

            <table>
                <thead class=" border-b-2 border-gray-100  text-base font-semibold ">
                    <tr class="grid grid-cols-12 py-2 px-6">
                        <th class="col-span-10 md:col-span-6 text-left">{{ $t('playlistPage.title')}}</th>
                        <th class="hidden md:block col-span-2 text-center">{{ $t('playlistPage.fileType')}}</th>
                        <th class="hidden md:block col-span-2 text-center">{{ $t('playlistPage.dateAdded')}}</th>
                        <th class="col-span-2 text-center">{{ $t('playlistPage.action')}}</th>
                    </tr>
                </thead>
                <div v-show="isAssetBroadcastListLoading" class="flex items-center justify-center w-full gap-2 p-6">
                    <div>{{ $t('general.loading')}}</div>
                    <span class="loading loading-bars loading-sm"></span>
                </div>
                    <!-- Playlists-->
                    <!-- <div v-for="playlist in playlistsList" :key="playlist.id" class="w-full"> -->
                        <!-- <router-link :to="`/teachers/${playlist.created_by.id}`" v-if="playlist.created_by" class="grid grid-cols-12 py-2 px-6 w-full border-b">
                            <div class="col-span-6 flex gap-4">
                                <div v-if="playlist.created_by && playlist.created_by.avatar_url" class="w-16 h-16 rounded-full">
                                    <img :src="playlist.created_by.avatar_url" alt="playlist image" class="w-full h-full object-cover">
                                </div>
                                <div class="my-auto">
                                    <p class="text-lg font-semibold">{{ playlist.title }}</p>
                                    <p class="text-sm">{{ $t('general.by') }} {{ playlist.created_by.name}}</p>
                                </div>
                            </div>
                            <div class="col-span-2"></div>
                            <div class="col-span-2"></div>
                            <div class="col-span-2 flex justify-center my-auto text-2xl"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
                        </router-link> -->
                        <div v-for="item in assetBroadcastList" :key="item.id">
                            <div class="grid grid-cols-12 py-3 md:px-6 w-full border-b">
                                <div @click="goToDocument(item.id)" class="col-span-10 md:col-span-6 flex gap-4">
                                    <div class="min-w-12 w-12 h-12 rounded-full">
                                        <img :src="item.thumbnails[0]?.url" alt="playlist image" class="w-full h-full object-cover">
                                    </div>
                                    <div class="my-auto">
                                        <p class="text-base font-semibold">{{ item.title }}</p>
                                        <div >
                                            <div class="">
                                                {{ item.groups.map(group => group.name).join(' | ') }}
                                            </div>
                                        </div>
                                        <p v-if="item.created_by" class="text-sm">{{ $t('general.by') }} {{ item.created_by.name }}</p>
                                    </div>
                                </div>
                                <td @click="goToDocument(item.id)" class="hidden md:block col-span-2 text-center my-auto">
                                    <div             
                                :class="{
                                    'bg-[#FEF3C7] text-[#92400E]': item.content_type === 1,
                                    'bg-green-100 text-green-800': item.content_type === 2,
                                    'bg-gray-100 text-gray-800': item.content_type === 3
                                }" class="badge badge-lg border-0 rounded-xl">
                                    {{ getContentTypeLabel(item.content_type) }}
                                </div>
                                </td>
                                <div @click="goToDocument(item.id)" class="hidden md:flex col-span-2 justify-center my-auto">
                                    <p>{{ item.created_at_formatted }}</p>
                                </div>
                                <!-- <div @click="addToPlaylist(item.id)" class="col-span-2 flex justify-center my-auto">
                                    
                                    <button
                                        v-if="!isAddedToPlaylist(item.id)"
                                        @click="addToPlaylist(item.id)"
                                        :data-theme="themeStore.selectedTheme"
                                        class="py-2 px-4 rounded-lg text-white"
                                    >
                                        {{ $t('general.add')}}
                                    </button>
                                    <div v-else class="text-gray-600 p-2 rounded-md shadow-lg flex gap-2">
                                        <p class="my-auto">{{ $t('playlistPage.addedToPlaylist')}}</p>
                                        <button
                                        @click="removeFromPlaylist(item.id)"
                                        :data-theme="themeStore.selectedTheme"
                                        class="py-2 px-4 rounded-lg text-white"
                                        >{{ $t('general.change')}}</button>
                                    </div>
                                </div> -->
                                <div class="col-span-2 flex justify-center my-auto cursor-pointer">
                                    <div v-if="!isAddedToPlaylist(item.id)" @click="addToPlaylist(item.id)">
                                        <i class="fa-solid fa-plus"></i>
                                    </div>
                                    <div v-else @click="removeFromPlaylist(item.id)">
                                        <i class="fa-solid fa-minus"></i>
                                    </div>
                                </div>

                            </div>
                        </div>
                    <!-- </div> -->
                
            </table>

            <!-- Pagination -->
            <div class="hidden lg:block">
                <CustomPagination
                    :showingFrom="showingFrom"
                    :showingTo="showingTo"
                    :totalItems="totalItems"
                    :totalPages="totalPages"
                    :currentPage="currentPage"
                    :itemsPerPage="itemsPerPage"
                    @change-page="changePage"
                />
            </div>
        </section>
    </div>
</template>