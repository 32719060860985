<script setup>
import axios from 'axios';
import { useModeStore } from '../store/mode.js';
import { useThemeStore } from '../store/theme.js';
import { getModeStrokeColor } from '../utils/commonFunctions.js';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';
import { useWorkspaceStore } from '../store/workspace.js';

const modeStore = useModeStore();
const themeStore = useThemeStore();
const modeStrokeColor = getModeStrokeColor();
console.log(modeStrokeColor);
const notificationTopBarStore = useNotificationTopBarStore();
const workspaceStore = useWorkspaceStore();

const closeNotificationTopBar = () => {
    notificationTopBarStore.setIsShowNotificationTopBar(false);
    notificationTopBarStore.resetNotificationTopBar();
}

const refreshPage = () => {
    window.location.reload();
}

const removeAssetBroadcastFromWorkspace = async (id) => {
    try {
        await axios.delete(`api/v1/workspaces/asset_broadcasts/${id}`);
        refreshPage();
    } catch (error) {
        console.error('Error removing playlist from workspace:', error);
    } finally {
        notificationTopBarStore.resetNotificationTopBar();
    }
}

const removePlaylistFromWorkspace = async (playlistId) => {
    try {
        await axios.delete(`api/v1/workspaces/playlists/${playlistId}`);
        workspaceStore.setAction('remove');
        workspaceStore.setPlaylistId(playlistId);
        refreshPage();
    } catch (error) {
        console.error('Error removing playlist from workspace:', error);
    } finally {
        notificationTopBarStore.resetNotificationTopBar();
    }
}

const addAssetBroadcastToWorkspace = async (id) => {
    try {
        await axios.post('api/v1/workspaces/asset_broadcasts', {
            asset_broadcast_id: id
        });
        refreshPage();
    } catch (error) {
        console.error('Error adding asset broadcast to workspace:', error);
    } finally {
        notificationTopBarStore.resetNotificationTopBar();
    }
}

const addPlaylistToWorkspace = async (playlistId) => {
    try {
        await axios.post('api/v1/workspaces/playlists', {
            playlist_id: playlistId
        });
        workspaceStore.setAction('add');
        workspaceStore.setPlaylistId(playlistId);
        refreshPage();
    } catch (error) {
        console.error('Error adding playlist to workspace:', error);
    } finally {
        notificationTopBarStore.resetNotificationTopBar();
    }
}

const cancelAction = () => {
    if (notificationTopBarStore.idUsedInAction) {
        if (notificationTopBarStore.notificationType === 'addAssetBroadcastToWorkspace') {
            removeAssetBroadcastFromWorkspace(notificationTopBarStore.idUsedInAction);
        } else if (notificationTopBarStore.notificationType === 'addPlaylistToWorkspace') {
            removePlaylistFromWorkspace(notificationTopBarStore.idUsedInAction);
        } else if (notificationTopBarStore.notificationType === 'removeAssetBroadcastFromWorkspace') {
            addAssetBroadcastToWorkspace(notificationTopBarStore.idUsedInAction);
        } else if (notificationTopBarStore.notificationType === 'removePlaylistFromWorkspace') {
            addPlaylistToWorkspace(notificationTopBarStore.idUsedInAction);
        }
    }
}

</script>

<template>
    <div class="flex justify-center w-full fixed left-0 top-0 md:top-[80px] z-10">
        <div :class="{ 'bg-[#14B8A6]': (themeStore.selectedTheme === 'green' && modeStore.selectedMode === 'dark'), 'bg-[#F97316]': (themeStore.selectedTheme === 'orange' && modeStore.selectedMode === 'dark'), 'bg-[#3B82F6]': (themeStore.selectedTheme === 'blue' && modeStore.selectedMode === 'dark'), 'bg-[#8B5CF6]': (themeStore.selectedTheme === 'purple' && modeStore.selectedMode === 'dark'), 'bg-[#DB2777]': (themeStore.selectedTheme === 'darkpink' && modeStore.selectedMode === 'dark'), 'bg-white' : modeStore.selectedMode === 'light'}" class="backdrop-blur bg-opacity-20 text-white flex gap-0 md:gap-4 justify-between rounded-md px-4 py-2 md:min-w-[800px]">
            <div class="my-auto text-xs md:text-base flex items-center gap-2">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 16.499C13.125 16.499 16.5 13.124 16.5 8.99902C16.5 4.87402 13.125 1.49902 9 1.49902C4.875 1.49902 1.5 4.87402 1.5 8.99902C1.5 13.124 4.875 16.499 9 16.499Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 5.99902V9.74902" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.99609 12.001H9.00283" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <div class="my-auto text-xs md:text-base">{{ notificationTopBarStore.notificationMessage }}</div> 
            </div>
            <div class="flex justify-start md:justify-end gap-1 md:gap-3 my-auto">
                <button @click="cancelAction()" :data-theme="themeStore.selectedTheme" class="px-4 py-2 text-xs md:text-sm text-white rounded-md text-center my-auto">Batal Tindakan</button>
                <i @click="closeNotificationTopBar()" class="fa fa-close my-auto fa-lg"></i>
            </div>
        </div>
    </div>
</template>