<script setup>
import { ref, watch, onMounted } from 'vue';
import { useModeStore } from '../store/mode.js';
import { useThemeStore } from '../store/theme.js';
import axios from 'axios';
import { getStrokeColor, getModeStrokeColor } from '../utils/commonFunctions.js';
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';

// const props = defineProps({
//     currentFileId: Number
// });

const modeStore = useModeStore();
const themeStore = useThemeStore();
const modeStrokeColor = getModeStrokeColor();

// const selectedFile = ref(null);
const addToPlaylistStore = useAddToPlaylistStore();
const strokeColor = getStrokeColor();
// const selectedPlaylistsToAdd = ref([]);
const searchQuery = ref('');
const searchPlaylistResults = ref([]);

// Function to perform search
const performSearch = () => {
    if (searchQuery.value.trim() !== '') {
        axios.get('api/v1/user-playlists', {
            params: {
                search: searchQuery.value
            }
        })
        .then((response) => {
        //filter out the playlist that already have the file
        // const filteredPlaylists = response.data.data.filter(playlist => 
        //     !playlist.asset_broadcasts_ids.includes(props.currentFileId)
        // );

        //searchPlaylistResults.value = response.data.data;
        addToPlaylistStore.setUserPlaylists(response.data.data);
        })
        .catch((error) => {
            console.log(error);
            searchPlaylistResults.value = [];
        });
    } else {
        fetchUserPlaylist();
    }
};

// Watch for changes in searchQuery to update search results
watch(searchQuery, () => {
  performSearch();
});

const fetchUserPlaylist = () => {
    axios.get('api/v1/user-playlists')
    .then((response) => {
        //searchPlaylistResults.value = response.data.data;
        addToPlaylistStore.setUserPlaylists(response.data.data);
    })
    .catch((error) => {
        console.log(error);
    });
}

onMounted(() => {
    fetchUserPlaylist();
});

//new code for changing checboxes to radio buttons
const selectedPlaylistToAdd = ref(null); // Only one selection allowed

const updateSelectedPlaylistToAdd = (playlistId) => {
    selectedPlaylistToAdd.value = playlistId;
    addToPlaylistStore.setSelectedPlaylistIds([playlistId]); // Wrap playlistId in an array
}

const cancelAddToPlaylist = () => {
    addToPlaylistStore.setSelectedPlaylistIds([]);
    addToPlaylistStore.setShowAddToPlaylistDropdown(false);

    // Uncheck the selected radio button
    selectedPlaylistToAdd.value = null;
    const radios = document.querySelectorAll("input[name='playlist-radio']");
    radios.forEach(radio => {
        radio.checked = false;
    });
}

const submitAddToPlaylist = () => {
    console.log('submitAddToPlaylist')
    if (addToPlaylistStore.selectedAssetBroadcastIds.length > 0 && selectedPlaylistToAdd.value !== null) {
        addToPlaylistStore.setIsAddingToPlaylistLoading(true);

        let playlistId = selectedPlaylistToAdd.value;
        axios.post(`api/v1/user-playlists/${playlistId}/asset-broadcasts`, {
            asset_broadcasts: addToPlaylistStore.selectedAssetBroadcastIds.map((id, index) => ({
                asset_broadcast_id: id,
                order: (index + 1)
            }))
        })
        .then((response) => {
            console.log('Request completed successfully', response);
            // Call cancelAddToPlaylist to close the modal
            cancelAddToPlaylist();
        })
        .catch((error) => {
            console.error('Error in the request', error);
        })
        .finally(() => {
            addToPlaylistStore.setIsAddingToPlaylistLoading(false);
        });
    }
}

//old code for checboxes
// const updateSelectedPlaylistToAdd = () => {
//     const checkboxes = document.querySelectorAll('input[id^="checkbox-playlist-"]');

//     // Get the IDs of all checked checkboxes
//     const checkedIds = Array.from(checkboxes)
//         .filter(checkbox => checkbox.checked)
//         .map(checkbox => Number(checkbox.id.replace('checkbox-playlist-', '')));

//     selectedPlaylistsToAdd.value = checkedIds;
//     addToPlaylistStore.setSelectedPlaylistIds(checkedIds);
// }

// const cancelAddToPlaylist = () => {
//     addToPlaylistStore.setShowAddToPlaylistDropdown(false);
//     addToPlaylistStore.setSelectedPlaylistIds([]);

//     // Get all checkboxes with IDs starting with 'checkbox-playlist-'
//     const checkboxes = document.querySelectorAll("input[id^='checkbox-playlist-']");
    
//     checkboxes.forEach(checkbox => {
//         checkbox.checked = false;
//     });
// }

// const submitAddToPlaylist = () => {
//     console.log('submitAddToPlaylist')
//     if (addToPlaylistStore.selectedAssetBroadcastIds.length > 0) {
//         addToPlaylistStore.setIsAddingToPlaylistLoading(true);
//         let promises = [];

//         selectedPlaylistsToAdd.value.forEach(playlistId => {
//             console.log('post api to add asset to playlist');
//             let promise = axios.post(`api/v1/user-playlists/${playlistId}/asset-broadcasts`, {
//                 asset_broadcasts: addToPlaylistStore.selectedAssetBroadcastIds.map((id, index) => ({
//                     asset_broadcast_id: id,
//                     order: (index + 1)
//                 }))
//             });
//             promises.push(promise);
//         });

//         Promise.all(promises)
//             .then((responses) => {
//                 console.log('All requests completed successfully', responses);
//                 // Call cancelAddToPlaylist to close the modal
//                 cancelAddToPlaylist();
//             })
//             .catch((error) => {
//                 console.error('Error in one or more requests', error);
//             })
//             .finally(() => {
//                 addToPlaylistStore.setIsAddingToPlaylistLoading(false);
//             });
//     }
// }

</script>

<template >
    <!-- <input ref="checkbox" @click="updateSelectedFile(props.currentFileId)" type="checkbox" :id="'checkbox-' + props.currentFileId" class="file-checkbox"/> -->
    <div :class="modeStore.selectedMode === 'dark' ? 'bg-black text-white' : 'bg-white text-black'"  class="add-to-playlist-card fixed md:top-[140px] left-1/2 transform -translate-x-1/2 h-[32rem] max-h-[32rem] w-80 z-50 px-3 py-3 rounded-xl border">
        <div>
            <div class="border-b pb-3">
                <div class="flex justify-between py-2">
                    <p class="text-lg">{{ $t('playlistPage.addToPlaylist')}}</p>
                    <i @click="cancelAddToPlaylist()" class="fa fa-close my-auto fa-lg"></i>
                </div>
                <div class="py-3">
                    <div :class="modeStore.selectedMode === 'dark' ? 'border-white' : 'border-black'" class="flex justify-between border rounded-lg cursor-pointer">
                        <input type="text" placeholder="Search here" class=" px-4 py-2 bg-transparent focus:outline-none cursor-pointer" v-model="searchQuery" @input="performSearch">
                        <svg class="my-auto mr-4" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5 21.5C16.7467 21.5 21 17.2467 21 12C21 6.75329 16.7467 2.5 11.5 2.5C6.25329 2.5 2 6.75329 2 12C2 17.2467 6.25329 21.5 11.5 21.5Z" :stroke="modeStrokeColor" stroke-width="1.61" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22 22.5L20 20.5" :stroke="modeStrokeColor" stroke-width="1.61" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <!-- <img v-if="modeStore.selectedMode === 'dark'" src="../assets/icons/search.png" alt="search icon" class=" right-0 top-0 bottom-0 m-auto h-4 pr-4 cursor-pointer">
                        <img v-else src="../assets/icons/search_black.png" alt="search icon" class=" right-0 top-0 bottom-0 m-auto h-4 pr-4 cursor-pointer"> -->
                    </div>
                </div>
                <!-- <div class="my-auto text-left">
                    <div @click="openCreateEditPlaylistModal('create')" class="w-1/2 py-2 px-4 rounded-md bg-gray-800 text-white"><span class="text-xl">+</span> Cipta Playlist</div>
                </div> -->
            </div>
            <!-- <div v-show="searchPlaylistResults.length > 0" class="max-h-[16rem] overflow-y-auto"> -->
            <div v-show="addToPlaylistStore.userPlaylists.length > 0" class="max-h-[16rem] overflow-y-auto">
                <div v-for="playlist in addToPlaylistStore.userPlaylists" :key="playlist.id" class="grid grid-cols-6 py-2">
                    <div class="flex gap-2 col-span-5">
                        <div>
                            <img :src="playlist.playlist_thumbnail" alt="playlist image" class="h-16 w-16 rounded-md">
                        </div>
                        <div class="text-left">
                            <p class="font-semibold">{{ playlist.title }}</p>
                            <p>{{ playlist.grade }}</p>
                        </div>
                    </div>
                    <!-- <div class="text-right my-auto col-span-1 pr-2">
                        <input ref="checkbox" @click="updateSelectedPlaylistToAdd()" type="checkbox" :id="'checkbox-playlist-' + playlist.id"/>
                        <input ref="radio" @click="updateSelectedPlaylistToAdd(playlist.id)" type="radio" :id="'radio-playlist-' + playlist.id" name="playlist-radio" />
                    </div> -->
                    <div :class="{ 'theme-green': themeStore.selectedTheme === 'green', 'theme-orange': themeStore.selectedTheme === 'orange', 'theme-blue': themeStore.selectedTheme === 'blue', 'theme-purple': themeStore.selectedTheme === 'purple', 'theme-darkpink': themeStore.selectedTheme === 'darkpink', 'light-mode': modeStore.selectedMode === 'light', 'dark-mode': modeStore.selectedMode === 'dark'  }" class="text-right my-auto col-span-1 pr-2">
                        <input ref="radio" @click="updateSelectedPlaylistToAdd(playlist.id)" type="radio" :id="'radio-playlist-' + playlist.id" name="playlist-radio" />
                        <label :for="'radio-playlist-' + playlist.id"></label>
                    </div>
                </div>
            </div>
            <div v-show="addToPlaylistStore.userPlaylists.length === 0"  class="text-center py-4">
                {{ $t('playlistPage.noPlaylistFound')}}
            </div>
            <div v-if="addToPlaylistStore.userPlaylists.length > 0" class="flex gap-2 justify-end py-4">
                <div class="max-h-6 max-w-6 my-auto" :class="addToPlaylistStore.isAddingToPlaylistLoading? 'spinner': ''" :style="{borderLeftColor: strokeColor}"></div>
                <button @click="cancelAddToPlaylist()" :disabled="addToPlaylistStore.isAddingToPlaylistLoading" :class="[addToPlaylistStore.isAddingToPlaylistLoading? 'opacity-20': '', modeStore.selectedMode === 'light' ? 'border-none bg-[#F9FAFB]': 'bg-white']" class=" text-black px-4 py-2 border rounded-lg">{{ $t('general.cancel')}}</button>
                <button @click="submitAddToPlaylist()" :disabled="addToPlaylistStore.isAddingToPlaylistLoading" :data-theme="themeStore.selectedTheme" :class="addToPlaylistStore.isAddingToPlaylistLoading? 'opacity-20': ''" class="px-4 py-2 text-white rounded-lg">{{ $t('general.add')}}</button>
            </div>
        </div>

    </div>
</template>