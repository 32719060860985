<script setup>
import { ref, onMounted, watch, defineProps, nextTick, onBeforeUnmount } from 'vue';
import { useModeStore } from '../store/mode.js';
import useIsMobile from '../composables/isMobile.js';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import axios from 'axios';
import { useActivePageStore } from '../store/activePageTab.js';
import { useUserStore } from '../store/user.js';
import { usePlaybackStore } from '../store/playbackStore.js';
import { useRoute } from 'vue-router';

// const router = useRouter();

import PageNavigator from '../components/PageNavigator.vue';

const props = defineProps({
  id: Number,
  isTeacherPlaylist: Boolean,
  assetBroadcastId: Number,
});
const isTeacherPlaylist = ref(props.isTeacherPlaylist === 'true');
const userStore = useUserStore();
const modeStore = useModeStore();
const route = useRoute();
const playbackStore = usePlaybackStore();
const activePageStore = useActivePageStore();
const { isMobile } = useIsMobile();

const playlistData = ref({});
const playlistAssetBroadcastList = ref([]);
const currentAssetBroadcastIndex = ref(0);
const loading = ref(false);
const loadingMedia = ref(false);
const player = ref(null);
const isExpanded = ref(false);

const contentType = {
  video: 1,
  pdf: 2,
  html: 3,
};
const breadcrumbs = ref([]);

const updateCurrentlyPlayingPlaylist = (playlist, document) => {
  userStore.setCurrentlyPlayingPlaylist(playlist, document);
};

const fetchPlaylistDetails = () => {
  const apiUrl = isTeacherPlaylist.value
    ? `/api/v1/playlists/${props.id}`
    : `/api/v1/user-playlists/${props.id}`;

  axios
    .get(apiUrl)
    .then((response) => {
      playlistData.value = response.data;
      console.log('playlistData', playlistData.value);

      // Generate breadcrumbs after fetching playlist data
      breadcrumbs.value = [
        { text: 'Laman Utama', to: { name: 'Dashboard Page' } },
        { text: 'Playlists', to: { name: 'Playlist Page' } },
        {
            text: playlistData.value.title || 'Playlist Detail',
            to: {
            name: 'Playlist Detail',
            query: { id: playlistData.value.id, isTeacherPlaylist: isTeacherPlaylist.value }
            }
        },
        { text: 'Play' },
        ];
    })
    .catch((error) => {
      console.error('Failed to fetch playlist details:', error);
    });
};

const fetchPlaylistAssetBroadcasts = () => {
  const apiUrl = isTeacherPlaylist.value
    ? `/api/v1/playlists/${props.id}/asset-broadcasts`
    : `/api/v1/user-playlists/${props.id}/asset-broadcasts`;

  axios
    .get(apiUrl)
    .then((response) => {
        playlistAssetBroadcastList.value = response.data.data;
        loading.value = false;
        // next tick
        nextTick(() => {
            // auto play assetBroadcastId if provided
            if (props.assetBroadcastId) {
                console.log(playlistAssetBroadcastList.value, props.assetBroadcastId)
  
                const assetBroadcastIndex = playlistAssetBroadcastList.value.findIndex(
                  (assetBroadcast) => assetBroadcast.id === props.assetBroadcastId
                );

                if (assetBroadcastIndex !== -1) {
                  currentAssetBroadcastIndex.value = assetBroadcastIndex;
                }
            }
        });
       
    })
    .catch((error) => {
      console.error('Failed to fetch playlist asset broadcasts:', error);
    });
};

// Update backend playlist progress
const updateAssetBroadcastProgress = (assetBroadcastId) => {
    const apiUrl = isTeacherPlaylist.value
        ? `/api/v1/playlists/${props.id}/progress`
        : `/api/v1/user-playlists/${props.id}/progress`;

    const current_seconds = (player.value && player.value.currentTime()) ? player.value.currentTime() : 0;
    axios
        .post(apiUrl, { asset_broadcast_id: assetBroadcastId, current_seconds: current_seconds })
        .then(() => {
            console.log('Asset broadcast progress updated successfully');
        })
        .catch((error) => {
            console.error('Failed to update asset broadcast progress:', error);
        });
};

// Initialize Player
const initPlayer = () => {
    if (player.value) {
        player.value.dispose();
    }

  const currentAssetBroadcast = playlistAssetBroadcastList.value[currentAssetBroadcastIndex.value];

  console.log('init player current asset broadcast', currentAssetBroadcast);
  if (currentAssetBroadcast.content_type === contentType.video) {
    nextTick(() => {
        try {
            player.value = videojs('mainVideo', {
                sources: [{ src: currentAssetBroadcast.file_url, type: 'video/mp4' }],
                autoplay: 'muted',
                controls: true,
                disablePictureInPicture: true,
                fluid: true,
            });
        } catch (error) {
            // if main video already initialised then just change the source
            player.value.src(currentAssetBroadcast.file_url);
        }

        // on current time changes
        player.value.on('timeupdate', () => {
            playbackStore.updateCurrentTime(player.value.currentTime());

            // every 10 seconds update progress
            if (player.value.currentTime() % 10 === 0) {
                updateAssetBroadcastProgress(currentAssetBroadcast.id);
            }
        });

        player.value.on('play', () => {
            playbackStore.updatePlayingState(true);
        });

        // on pause also update progress
        player.value.on('pause', () => {
            playbackStore.updatePlayingState(false);
            updateAssetBroadcastProgress(currentAssetBroadcast.id);
        });

        // duration of video
        player.value.on('durationchange', () => {
            playbackStore.updateDuration(player.value.duration());
        });

        player.value.on('ended', () => {
            updateAssetBroadcastProgress(currentAssetBroadcast.id);
            playNextAssetBroadcast();
        });

        updateCurrentlyPlayingPlaylist(playlistData.value, currentAssetBroadcast);
    });
  }
};

// change video source
const changeVideoSource = async () => {
  const currentAssetBroadcast = playlistAssetBroadcastList.value[currentAssetBroadcastIndex.value];

  if (currentAssetBroadcast.content_type === contentType.video) {
    // check if mainVideo has init videojs if not init first
    if (!player.value) {
      initPlayer();
    } else {
      player.value.src(currentAssetBroadcast.file_url);
    }
  }
};

// go back 
const playPreviousAssetBroadcast = () => {
  if (currentAssetBroadcastIndex.value > 0) {
    currentAssetBroadcastIndex.value--;
    changeVideoSource();
  }
};

// go next
const playNextAssetBroadcast = () => {
  if (currentAssetBroadcastIndex.value < playlistAssetBroadcastList.value.length - 1) {
    currentAssetBroadcastIndex.value++;
    changeVideoSource();
  }
};

// watch if current asset broadcast index changes, then update progress
// and change video source if the selected asset is a video
watch(currentAssetBroadcastIndex, (newIndex) => {
  const currentAssetBroadcast = playlistAssetBroadcastList.value[newIndex];
  updateAssetBroadcastProgress(currentAssetBroadcast.id);

  updateCurrentlyPlayingPlaylist(playlistData.value, currentAssetBroadcast);
  changeVideoSource();
});

// expand the iframe or video player to full and hide the playlist items
const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};

onMounted(() => {
  console.log('playlistplay props.isTeacherPlaylist=========',props.isTeacherPlaylist)
  console.log('isTeacherPlaylist', isTeacherPlaylist.value);
 activePageStore.setSelectedActivePage(6)
  loading.value = true;
  fetchPlaylistDetails();
  fetchPlaylistAssetBroadcasts();
});

watch(playlistAssetBroadcastList, () => {
  if (playlistAssetBroadcastList.value.length > 0) {
    console.log('playlistAssetBroadcastList', playlistAssetBroadcastList.value);
    // if assetId provided in the query, then play that asset
    if (route.query.assetId) {
      const assetBroadcastIndex = playlistAssetBroadcastList.value.findIndex(
        (assetBroadcast) => assetBroadcast.id === Number(route.query.assetId)
      );

      if (assetBroadcastIndex !== -1) {
        currentAssetBroadcastIndex.value = assetBroadcastIndex;
      }
    }

    // start from which seconds
    if (route.query.playFrom) {
        if (player.value) {
            player.value.currentTime(route.query.playFrom);
        }
    }

    initPlayer();
  }
});

// router.beforeEach((to, from, next) => {
//   if (player.value) {
//     player.value.pause();
//     playbackStore.updatePlayingState(false);
//   }
//   next();
// });

onBeforeUnmount(() => {
    console.log('PlaylistPlay before unmount');
    if (player.value) {
        // playbackStore.updatePlayingState(false);
        player.value.dispose();
        player.value = null;
    }
});


</script>

<template>
    <div class="min-h-screen">
      <page-navigator :breadcrumbs="breadcrumbs" />
      <section
        class="flex flex-col gap-8 rounded-xl p-[30px] bg-transparent"
        :class="{'text-black': modeStore.selectedMode === 'light', ' text-white': modeStore.selectedMode === 'dark'}"
      >
        <div v-show="loading" class="flex items-center justify-center w-full gap-2 my-4">
            <div>{{ $t('general.loading')}}</div>
            <span class="loading loading-bars loading-sm"></span>
        </div>
        <div
          class="flex items-center justify-between mb-4"
          :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
        >
          <!-- <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ playlistData.title }}</h2> -->
        </div>
  
        <div class="filament-main-content h-full w-full mx-auto grid" :class="{ 'md:grid-cols-3': !isExpanded, 'md:grid-cols-1': isExpanded }" :style="{ transition: 'grid-template-columns 0.3s ease-in-out' }">
          <div v-if="loadingMedia" class="col-span-12" :class="{ 'md:col-span-2': !isExpanded }">
            <div class="w-full h-full flex items-center justify-center">
              <div :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}" class="spinner-border" role="status">
                <span class="visually-hidden">{{ $t('general.loading')}}</span>
              </div>
            </div>
          </div>
  
          <div v-if="!loading && playlistAssetBroadcastList.length === 0" class="col-span-12 flex items-center justify-center">
            <p>No data available</p>
          </div>
  
          <div v-if="playlistAssetBroadcastList.length > 0" class="col-span-12" :class="{ 'md:col-span-2': !isExpanded }">
            <div class="flex justify-between mb-4">
              <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ playlistData.title }}</h2>
              <button @click="toggleExpand" class="btn btn-primary">{{ isExpanded ? 'Show Playlist' : 'Expand' }}</button>
            </div>
            <div v-if="playlistAssetBroadcastList[currentAssetBroadcastIndex].content_type === contentType.video" class="h-full media-player-wrapper relative">
              <video id="mainVideo" class="video-js" width="100%" height="100%"></video>
            </div>
            <div v-if="playlistAssetBroadcastList[currentAssetBroadcastIndex].content_type === contentType.pdf" class="pdf-media-wrapper">
              <iframe :src="playlistAssetBroadcastList[currentAssetBroadcastIndex].file_url" width="100%" height="580" alt="pdf" />
            </div>
            <div v-if="playlistAssetBroadcastList[currentAssetBroadcastIndex].content_type === contentType.html" class="html-media-wrapper">
              <iframe :src="playlistAssetBroadcastList[currentAssetBroadcastIndex].file_url" width="100%" height="580" frameborder="0" style="border: 1px solid #000;"></iframe>
            </div>
          </div>
  
          <div v-if="!isExpanded && playlistAssetBroadcastList.length > 0" :class="{'text-black bg-white bg-opacity-20': modeStore.selectedMode === 'light', 'text-white bg-black bg-opacity-10': modeStore.selectedMode === 'dark'}" class="playlistContent col-span-12 md:col-span-1 flex flex-col p-4 fi-section rounded-xl shadow-sm ml-2">
            <div class="playlistContent-title mb-2">Playlist</div>
            <div :class="{'text-black': modeStore.selectedMode === 'light', 'text-white ': modeStore.selectedMode === 'dark'}" class="scrollable-playlist-content w-full px-2 text-sm p-4 rounded-lg bg-transparent">
              <div
                v-for="(item, index) in playlistAssetBroadcastList"
                :key="item.id"
                :class="{'bg-white bg-opacity-10': (currentAssetBroadcastIndex === index && modeStore.selectedMode === 'dark'), ' bg-white bg-opacity-20': (currentAssetBroadcastIndex === index && modeStore.selectedMode === 'light')}"
                class="flex flex-row items-center w-full h-24 mb-2 p-2 cursor-pointer rounded-md"
                @click="currentAssetBroadcastIndex = index"
              >
                <div class="thumbnail-wrapper w-20 flex-shrink-0 relative">
                  <img :src="item.thumbnails[0]?.url" alt="thumbnail" class="w-full" />
                  <p class="bg-black p-[2px] rounded-sm text-xs">{{ item.duration }}</p>
                </div>
                <div class="playlist-item-details ml-2 flex flex-col justify-between">
                  <span class="block truncate max-w-56">{{ item.title }}</span>
                  <span>{{ item.content_type_text }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div v-if="playlistAssetBroadcastList.length > 0" :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}">
          <span class="text-lg">{{ playlistAssetBroadcastList[currentAssetBroadcastIndex].title }}</span>
          <div class="mt-2 p-4 flex flex-col h-fit rounded-md border"  v-if="playlistAssetBroadcastList[currentAssetBroadcastIndex].description ">
            <div class="w-full">
              <p>{{ playlistAssetBroadcastList[currentAssetBroadcastIndex].description }}</p>
            </div>
          </div>
        </div>
  
        <div class="flex justify-between mt-4">
          <button v-if="currentAssetBroadcastIndex > 0" @click="playPreviousAssetBroadcast" class="btn btn-primary">Previous</button>
          <button v-if="currentAssetBroadcastIndex < playlistAssetBroadcastList.length - 1" @click="playNextAssetBroadcast" class="btn btn-primary">Next</button>
        </div>
      </section>
    </div>
  </template>
  
  <style>
  /* .media-active {
    background-color: rgba(0.0667, 0.0941, 0.1529, 0.2);
  } */
  .thumbnail-wrapper img {
    object-fit: cover;
  }
  .playlistContent {
    max-height: 580px;
  }
  .scrollable-playlist-content {
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .playlist-item-details {
    word-break: break-all;
  }
  .video-js {
    height: 100% !important;
  }
  </style>