// src/utils/commonFunctions.js
import { computed } from 'vue';
import { useModeStore } from '../store/mode.js';
import { useThemeStore } from '../store/theme.js';

export const getStrokeColor = () => {
    const themeStore = useThemeStore();
    return computed(() => {
      switch (themeStore.selectedTheme) {
        case 'green':
          return '#14B8A6';
        case 'blue':
          return '#3B82F6';
        case 'purple':
          return '#8B5CF6';
        case 'darkpink':
          return '#DB2777';
        case 'orange':
          return '#F97316';
        default:
          return '#F97316';
      }
    });
  };

export const getModeStrokeColor = () => {
    const modeStore = useModeStore();
    return computed(() => {
        // return modeStore.selectedMode === 'light' ? '#6B7280' : 'white';
        return modeStore.selectedMode === 'light' ? 'black' : 'white';
    });
};

export const getActiveTextColor = () => {
  const themeStore = useThemeStore();
  return computed(() => {
    switch (themeStore.selectedTheme) {
      case 'green':
        return 'theme-green-text';
      case 'blue':
        return 'theme-blue-text';
      case 'purple':
        return 'theme-purple-text';
      case 'darkpink':
        return 'theme-darkpink-text';
      case 'orange':
        return 'theme-orange-text';
      default:
        return 'theme-orange-text';
    }
  });
}

export const getActiveBackgroundColor = () => {
  const themeStore = useThemeStore();
  return computed(() => {
    switch (themeStore.selectedTheme) {
      case 'green':
        return 'bg-[#F0FDFA]';
      case 'blue':
        return 'bg-[#EFF6FF]';
      case 'purple':
        return 'bg-[#F5F3FF]';
      case 'darkpink':
        return 'bg-[#FDF2F8]';
      case 'orange':
        return 'bg-[#FFF0DA]';
      default:
        return 'bg-[#FFF0DA]';
    }
  });
}

export function convertTime(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.round((now - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const days = Math.round(hours / 24);
  const weeks = Math.round(days / 7);
  const months = Math.round(days / 30);
  const years = Math.round(days / 365);

  if (seconds < 60) {
      return `${seconds} detik lalu`;
  } else if (minutes < 60) {
      return `${minutes} minit lalu`;
  } else if (hours < 24) {
      return `${hours} jam lalu`;
  } else if (days < 7) {
      return `${days} hari lalu`;
  } else if (weeks < 4) {
      return `${weeks} minggu lalu`;
  } else if (months < 12) {
      return `${months} bulan lalu`;
  } else {
      return `${years} tahun lalu`;
  }
}

export function getPaginationBackgroundColor() {
  const themeStore = useThemeStore();
  return computed(() => {
    switch (themeStore.selectedTheme) {
      case 'green':
        return 'bg-[#14B8A6]';
      case 'blue':
        return 'bg-[#3B82F6]';
      case 'purple':
        return 'bg-[#8B5CF6]';
      case 'darkpink':
        return 'bg-[#DB2777]';
      case 'orange':
        return 'bg-[#F4681A]';
      default:
        return 'bg-[#F4681A]';
    }
  });
}

export function getContentTypeLabel(contentType) {
  switch(contentType) {
      case 1:
          return 'Video';
      case 2:
          return 'Interaktif';
      case 3:
          return 'Dokumen';
      default:
          return 'Unknown';
  }
}

export function formatTime(timeInSeconds) {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
}