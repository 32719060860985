<script>
import { useModeStore } from '../store/mode.js';
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';

// Import Images
import Undo from '../assets/icons/editor/undo.png';
import Redo from '../assets/icons/editor/redo.png';
import Bold from '../assets/icons/editor/bold.png';
import Italic from '../assets/icons/editor/italic.png';
import UnderlineIcon from '../assets/icons/editor/underline.png';
import LeftAlign from '../assets/icons/editor/left_align.png';
import CenterAlign from '../assets/icons/editor/center_align.png';
import RightAlign from '../assets/icons/editor/right_align.png';
import Justify from '../assets/icons/editor/justify.png';
import OrderedListIcon from '../assets/icons/editor/ordered_list.png';
import BulletListIcon from '../assets/icons/editor/bullet.png';

export default {
    components: {
        EditorContent,
    },
    props: {
        modelValue: {
            type: String,
            default: '',
        },
    },
    emits: ['update:modelValue'],
    data() {
        const modeStore = useModeStore();

        return {
            modeStore,
            editor: null,
            Undo,
            Redo,
            Bold,
            Italic,
            UnderlineIcon,
            LeftAlign,
            CenterAlign,
            RightAlign,
            Justify,
            OrderedListIcon,
            BulletListIcon,
        }
    },
    computed: {
        editorClass() {
            return this.modeStore.selectedMode === 'light' ? 'border-gray-300 text-black' : 'border-gray-700 text-gray-400';
        },
    },
    watch: {
        'modeStore.selectedMode'() {
            this.editor.destroy();
            this.initializeEditor();
        },
        modelValue(value) {
            // HTML
            const isSame = this.editor.getHTML() === value

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
    },
    mounted() {
        this.initializeEditor();
    },
    beforeUnmount() {
        this.editor.destroy()
    },
    methods: {
        initializeEditor() {
            this.editor = new Editor({
                editorProps: {
                    attributes: {
                        class: `border px-8 py-4 rounded-lg min-h-80 lg:min-h-32 max-h-64 overflow-y-auto focus:outline-none focus:shadow-outline ${this.editorClass}`,
                    },
                },
                extensions: [
                    StarterKit,
                    TextAlign.configure({
                        types: ['heading', 'paragraph'],
                    }),
                    Underline,
                    BulletList.configure({
                        HTMLAttributes: {
                        class: 'list-disc'
                        }
                    }),
                    OrderedList.configure({
                        HTMLAttributes: {
                            class: 'list-decimal',
                        },
                    })
                ],
                content: this.modelValue,
                onUpdate: () => {
                    // HTML
                    this.$emit('update:modelValue', this.editor.getHTML())

                    // JSON
                    // this.$emit('update:modelValue', this.editor.getJSON())
                },
            })
        },
    },
};
</script>

<template>
    <section 
        v-if="editor"
        class="flex items-center flex-wrap rounded-lg gap-y-1"
    >
        <button
            @click="editor.chain().focus().undo().run()"
            :disabled="!editor.can().chain().focus().undo().run()"
        >
            <img :src="Undo" alt="Undo Icon" class="h-8 p-1" />
        </button>
        
        <button
            @click="editor.chain().focus().redo().run()"
            :disabled="!editor.can().chain().focus().redo().run()"
        >
            <img :src="Redo" alt="Redo Icon" class="h-8" />
        </button>
        
        <button 
            @click="editor.chain().focus().toggleBold().run()" 
            :disabled="!editor.can().chain().focus().toggleBold().run()" 
            :class="{ 'is-active': editor.isActive('bold') }"
        >
            <img :src="Bold" alt="Bold Icon" class="h-8" />
        </button>

        <button 
            @click="editor.chain().focus().toggleItalic().run()"
            :disabled="!editor.can().chain().focus().toggleItalic().run()"
            :class="{ 'is-active': editor.isActive('italic') }"
        >
            <img :src="Italic" alt="Italic Icon" class="h-8" />
        </button>

        <button
            @click="editor.chain().focus().toggleUnderline().run()"
            :class="{ 'is-active': editor.isActive('underline') }"
        >
            <img :src="UnderlineIcon" alt="Underline Icon" class="h-8" />
        </button>

        <button
            @click="editor.chain().focus().setTextAlign('left').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
        >
            <img :src="LeftAlign" alt="Left Align Icon" class="h-8" />
        </button>

        <button
            @click="editor.chain().focus().setTextAlign('center').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
        >
            <img :src="CenterAlign" alt="Center Align Icon" class="h-8" />
        </button>
        
        <button
            @click="editor.chain().focus().setTextAlign('right').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
        >
            <img :src="RightAlign" alt="Right Align Icon" class="h-8" />
        </button>

        <button
            @click="editor.chain().focus().setTextAlign('justify').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
        >
            <img :src="Justify" alt="Justify Icon" class="h-8" />
        </button>

        <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
            <img :src="OrderedListIcon" alt="OrderedList Icon" class="h-8" />
        </button>

        <button
            @click="editor.chain().focus().toggleBulletList().run()"
            :class="{ 'is-active': editor.isActive('bulletList') }"
        >
            <img :src="BulletListIcon" alt="Bullet List Icon" class="h-8 p-1" />
        </button>
    </section>

    <div :key="modeStore.selectedMode">
        <editor-content :editor="editor"/>
    </div>
</template>

<style scoped>
.is-active {
    background-color: rgb(229 231 235);
    color: white;
    border-radius: 0.375rem;
}
</style>
  
