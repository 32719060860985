<template>
  <button
    :class="[
      baseClasses,
      variantClasses,
      sizeClasses,
      disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
    ]"
    v-bind="$attrs"
    @click="$emit('click', $event)"
  >
    <slot></slot>
  </button>
</template>

<script setup>
import { computed, defineEmits, defineProps } from 'vue';
import { useModeStore } from '../store/mode.js';

const props = defineProps({
  variant: {
    type: String,
    default: 'primary',
    validator: (value) => ['primary', 'secondary', 'tertiary'].includes(value)
  },
  size: {
    type: String,
    default: 'medium',
    validator: (value) => ['small', 'medium', 'large'].includes(value)
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const modeStore = useModeStore();

const baseClasses = computed(() => [
  'font-semibold',
  'rounded-md',
  'transition-colors',
  'duration-200',
  'ease-in-out',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:ring-orange-500'
]);

const variantClasses = computed(() => {
  const isDark = modeStore.selectedMode === 'dark';
  switch (props.variant) {
    case 'primary':
      return ['bg-orange-500', 'text-white', 'hover:bg-orange-400'];
    case 'secondary':
      return isDark
        ? ['bg-gray-700', 'text-white', 'hover:bg-gray-600']
        : ['bg-gray-200', 'text-gray-800', 'hover:bg-gray-300'];
    case 'tertiary':
      return isDark
        ? ['bg-transparent', 'text-white', 'hover:bg-gray-700']
        : ['bg-transparent', 'text-gray-800', 'hover:bg-gray-100'];
    default:
      return [];
  }
});

const sizeClasses = computed(() => {
  switch (props.size) {
    case 'small':
      return ['px-3', 'py-1', 'text-sm'];
    case 'medium':
      return ['px-4', 'py-2', 'text-base'];
    case 'large':
      return ['px-6', 'py-3', 'text-lg'];
    default:
      return [];
  }
});

defineEmits(['click']);
</script>