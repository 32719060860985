import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import './style.css'
import '@fortawesome/fontawesome-free/css/all.css'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
// import 'v-onboarding/dist/style.css';
import InstantSearch from 'vue-instantsearch/vue3/es';
import piniaPluginPersistedState from "pinia-plugin-persistedstate"
import i18n from './utils/i18n'

import axios from 'axios';
const pinia = createPinia()
pinia.use(piniaPluginPersistedState);

const app = createApp(App)

app.use(pinia)
app.use(router);
app.use(InstantSearch)
app.use(i18n)
app.mount('#app')
app.component('VueDatePicker', VueDatePicker);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// Request interceptor
axios.interceptors.request.use((config) => {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        config.headers = Object.assign({
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }, config.headers);
    }
    return config
});

// Response interceptor
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response) {
            console.log('error.response', error.response)
            if (error.response.status === 401 || (error.response.status === 400 && error.response.data.errors === 'Unauthenticated.')) {
              console.log('Redirecting to login page');
              // localStorage.removeItem('accessToken');
              // localStorage.removeItem('user');
              try {
                router.push({ name: 'Login Page' });
              } catch (err) {
                console.error('Redirection error:', err);
              }
            } else if (error.response.status === 422) {
              console.log('Validation error');
            } else {
              // Other errors, redirect to a generic error page
              // router.push({ name: 'ErrorPage' });
            }
          }
      return Promise.reject(error);
    }
);

// Ensure overflow-hidden class is removed when navigating away
window.addEventListener('beforeunload', function() {
  document.body.classList.remove('overflow-hidden');
});