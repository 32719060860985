import { defineStore } from 'pinia'

export const useNotificationTopBarStore = defineStore('showNotificationTopBar', {
    state: () => {
      return {
        isShowNotificationTopBar: false,
        notificationMessage: 'message',
        notificationType: '', // 'addAssetBroadcastToWorkspace', 'addPlaylistToWorkspace', 'removeAssetBroadcastFromWorkspace', 'removePlaylistFromWorkspace'
        idUsedInAction: null,
      }
    },
    actions: {
        setIsShowNotificationTopBar(value) {
            this.isShowNotificationTopBar = value
            if (this.isShowNotificationTopBar) {
            document.body.classList.add('overflow-hidden');
            } else {
            document.body.classList.remove('overflow-hidden');
            }
        },
        setNotificationMessage(notificationMessage) {
            this.notificationMessage = notificationMessage
        },
        setNotificationType(notificationType) {
            this.notificationType = notificationType
        },
        resetNotificationTopBar() {
            this.isShowNotificationTopBar = false
            this.notificationMessage = ''
            this.notificationType = ''
            document.body.classList.remove('overflow-hidden');
        },
        setIdUsedInAction(id) {
            this.idUsedInAction = id
        }
    },
  })