<script setup>
import { ref, defineEmits, defineProps, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

import { getStrokeColor } from '../utils/commonFunctions.js';
import { useModeStore } from '../store/mode.js';
import { useThemeStore } from '../store/theme.js';
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';

const props = defineProps({
  mode: String, // Prop for mode (create or edit)
  playlistId: Number, // Prop for playlist id
  isAtPlaylistPages: Boolean, // Prop for checking if user is at playlist pages, if yes, display the upload image div
});

const addToPlaylistStore = useAddToPlaylistStore();
const strokeColor = getStrokeColor();
const modeStore = useModeStore();
const themeStore = useThemeStore();
const router = useRouter();
const emits = defineEmits(["close-create-edit-playlist-modal", "refresh-playlist-data"]);

const isLoading = ref(false);

const closeModal = () => {
    document.body.classList.remove('overflow-hidden');
    emits("close-create-edit-playlist-modal")
    addToPlaylistStore.setShowCreateOrEditPlaylistModal(false);
}

const refreshPlaylistDataApi = () => {
    emits("refresh-playlist-data")
}

const createPlaylist = () => {
    isLoading.value = true;
    console.log('Create Playlist');
    const formData = new FormData();
    formData.append('title', playlistData.value.title);
    formData.append('description', 'description');
    if (selectedThumbnailFile.value) {
        const blob = new Blob([selectedThumbnailFile.value], { type: selectedThumbnailFile.value.type });
        formData.append('thumbnail', blob, selectedThumbnailFile.value.name);
    }

    axios.post('api/v1/user-playlists', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    .then((response) => {
        console.log('create playlist response', response);
        if (addToPlaylistStore.redirectToCreatePlaylistPage) {
            addToPlaylistStore.setShowCreateOrEditPlaylistModal(false)
            router.push({ name: 'Playlist Detail', query: { id: response.data.id, isTeacherPlaylist: 'false' } });
        } else {
            refreshAddtoPlaylistDropdown();
        }
        
    })
    .catch((error) => {
        console.log(error);
        errors.value = error.response.data.errors;
    })
    .finally(() => {
        isLoading.value = false;
    });
}

const refreshAddtoPlaylistDropdown = () => {
    console.log('refreshAddtoPlaylistDropdown')
    axios.get('api/v1/user-playlists')
    .then((response) => {
        addToPlaylistStore.setUserPlaylists(response.data.data);
    })
    .catch((error) => {
        console.log(error);
    })
    .finally(() => {
        closeModal();
    });
}

const editPlaylist = () => {
    console.log('Edit Playlist');
    isLoading.value = true;
    const formData = new FormData();
    formData.append('title', playlistData.value.title);
    formData.append('description', 'description');
    if (selectedThumbnailFile.value) {
        console.log('edit playlisyt selectedThumbnailFile.value', selectedThumbnailFile.value)
        const blob = new Blob([selectedThumbnailFile.value], { type: selectedThumbnailFile.value.type });
        formData.append('thumbnail', blob, selectedThumbnailFile.value.name);
    }

    formData.append('_method', 'put');

    axios.post(`api/v1/user-playlists/${props.playlistId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    .then((response) => {
        console.log(response);
        getPlaylistData();
        closeModal();
        refreshPlaylistDataApi();
    })
    .catch((error) => {
        console.log(error);
        errors.value = error.response.data.errors;
    })
    .finally(() => {
        isLoading.value = false;
    });
}

const submitCreateEditPlylist = () => {
    console.log('Submit Create Edit Playlist');
    if (addToPlaylistStore.modalMode === 'create') {
        createPlaylist();
    } else {
        editPlaylist();
    }
}

const playlistData = ref({
    title: '',
    thumbnail: null,
    // description: '',
})
const errors = ref(null);

const selectedThumbnailFile = ref(null);

const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    selectedThumbnailFile.value = file;

    const reader = new FileReader();
    reader.onload = (e) => {
        playlistData.value.thumbnail = e.target.result;
    };
    reader.readAsDataURL(file);
}

const triggerFileInput = () => {
    fileInputRef.value.click();
};

const fileInputRef = ref(null);

const getPlaylistData = () => {
    axios.get(`api/v1/user-playlists/${props.playlistId}`)
        .then((response) => {
            console.log('createeditplaylistmodal getPlaylistData', response);
            playlistData.value.thumbnail = response.data.playlist_thumbnail;
            playlistData.value.title = response.data.title;
            //playlistData.value.description = response.data.description;
        })
        .catch((error) => {
            console.log(error);
        });
}

onMounted(() => {
    if (props.playlistId) {
        getPlaylistData();
    }
})
</script>

<template>
    <div>
        <div class="fixed inset-0 flex items-center justify-center" style="z-index:999;">
            <div class="fixed inset-0 bg-black opacity-80"></div>
            <div class="relative p-8 rounded-lg shadow-lg md:w-1/2" :class="{'bg-white': modeStore.selectedMode === 'light', 'bg-custom-blue-gray text-white': modeStore.selectedMode === 'dark'}">
                <div class="flex justify-between">
                    <h1 v-if="addToPlaylistStore.modalMode == 'create'" class="text-2xl font-semibold py-2">{{ $t('playlistPage.createPlaylist')}}</h1>
                    <h1 v-else class="text-2xl font-semibold py-2">{{ $t('playlistPage.editPlaylist') }}</h1>
                    <div @click="closeModal" class="cursor-pointer">
                        <img v-if="modeStore.selectedMode === 'dark'" src="../assets/icons/white-X.png" alt="close" class="h-6">
                        <img v-else src="../assets/icons/black-X.png" alt="close" class="h-6">
                    </div>
                </div>
                <div class="">
                    <div v-if="props.isAtPlaylistPages" :class="{'bg-gray-400': modeStore.selectedMode === 'light', 'bg-black text-white': modeStore.selectedMode === 'dark'}" class="w-full h-56 relative mb-2 rounded-xl border-gray-800 border-2 border-dashed">
                        <img v-if="playlistData.thumbnail" :src="playlistData.thumbnail" alt="Thumbnail" class="w-full h-full object-cover rounded-xl">
                        <input type="file" ref="fileInputRef" class="hidden" @change="handleFileInputChange">
                        <div class="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-10 md:h-16 cursor-pointer">
                            <div @click="triggerFileInput" class="flex flex-col items-center">
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.87435 10.9167C11.0249 10.9167 11.9577 9.98393 11.9577 8.83333C11.9577 7.68274 11.0249 6.75 9.87435 6.75C8.72376 6.75 7.79102 7.68274 7.79102 8.83333C7.79102 9.98393 8.72376 10.9167 9.87435 10.9167Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M14.0423 2.58398H9.87565C4.66732 2.58398 2.58398 4.66732 2.58398 9.87565V16.1257C2.58398 21.334 4.66732 23.4173 9.87565 23.4173H16.1257C21.334 23.4173 23.4173 21.334 23.4173 16.1257V10.9173" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.9062 5.70898H22.6354" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M19.7715 8.57292V2.84375" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M3.28125 20.2402L8.41667 16.7923C9.23958 16.2402 10.4271 16.3027 11.1667 16.9382L11.5104 17.2402C12.3229 17.9382 13.6354 17.9382 14.4479 17.2402L18.7812 13.5215C19.5937 12.8236 20.9063 12.8236 21.7188 13.5215L23.4167 14.9798" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                                <div class="text-xs text-gray-300"><span class="text-white font-semibold">Muat naik</span> atau seret & lepas gambar</div>
                                <div class="text-xs text-gray-300 pb-4">(gambar mestilah tidak melebihi 100kb)</div>
                                <div class="text-xs text-gray-300 flex gap-4">
                                    <div class="flex items-center gap-1">
                                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="4.5" cy="4.5" r="3" fill="#9CA3AF"/>
                                        </svg>
                                        <div>Aspek ratio 5.41:1  </div>
                                    </div>
                                    <div class="flex items-center gap-1">
                                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="4.5" cy="4.5" r="3" fill="#9CA3AF"/>
                                        </svg>
                                        <div>Saiz disyorkan 1352x250 </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="playlistData.thumbnail" class="w-full absolute inset-0 bg-black opacity-50 rounded-xl"></div>
                        <!-- <svg @click="triggerFileInput" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-10 md:h-16 cursor-pointer" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M36.667 6.66602H30.0003C13.3337 6.66602 6.66699 13.3327 6.66699 29.9994V49.9994C6.66699 66.666 13.3337 73.3327 30.0003 73.3327H50.0003C66.667 73.3327 73.3337 66.666 73.3337 49.9994V43.3327" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M53.4663 10.0675L27.1996 36.3342C26.1996 37.3342 25.1996 39.3008 24.9996 40.7342L23.5663 50.7675C23.0329 54.4008 25.5996 56.9342 29.2329 56.4342L39.2663 55.0008C40.6663 54.8008 42.6329 53.8008 43.6663 52.8008L69.9329 26.5342C74.4663 22.0008 76.5996 16.7342 69.9329 10.0675C63.2663 3.40084 57.9996 5.53418 53.4663 10.0675Z" stroke="white" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M49.7002 13.834C51.9335 21.8007 58.1669 28.034 66.1669 30.3007" stroke="white" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> -->

                    </div>
                    <div class="mb-2">
                        <input v-model="playlistData.title" type="text" id="name" placeholder="Tajuk Playlist Pilihan Anda" :class="modeStore.selectedMode === 'dark'? 'border-gray-600': ''"  class="bg-transparent block w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:border-blue-500">
                        <div v-if="errors && errors.title" class="text-red-500 text-sm">{{ errors.title.join(",") }}</div>
                    </div>
                    <!-- <div class="mb-2">
                        <textarea v-model="playlistData.description" rows="4" id="description" placeholder="Enter playlist description" :class="modeStore.selectedMode === 'dark'? 'border-gray-600': ''"  class="bg-transparent block w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:border-blue-500"></textarea>
                        <div v-if="errors && errors.description" class="text-red-500 text-sm">{{ errors.description.join(",") }}</div>
                    </div> -->
                    <div class="flex gap-2 justify-end my-auto pt-4">
                        <div class="max-h-6 max-w-6 my-auto" :class="isLoading? 'spinner': ''" :style="{borderLeftColor: strokeColor}"></div>
                        <button :disabled="isLoading" @click="submitCreateEditPlylist()" :data-theme='themeStore.selectedTheme' :class="isLoading? 'opacity-50': 'cursor-pointer'" class="text-sm py-2 px-4 rounded-lg text-white ">{{ $t('general.save')}}</button>
                        <button :disabled="isLoading" @click="closeModal" :class="[modeStore.selectedMode === 'dark'? 'border-gray-600 text-white': 'text-black', isLoading? 'opacity-50': 'cursor-pointer']"  class="text-sm py-2 px-4 rounded-lg border">{{ $t('general.cancel')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>