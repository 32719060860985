<script setup>
// eslint-disable-next-line
const props = defineProps({
    playlistId: Number,
    image: String,
    subject: String,
    teacher: String,
    grade: Array,
    documentCount: Number,
    interactionCount: Number,
    videoCount: Number,
    isAddedToWorkspace: Boolean,
    isTeacherPlaylist: Boolean,
});

import axios from 'axios';
import { ref, defineEmits } from 'vue';
import { useModeStore } from '../store/mode.js';
import { useWorkspaceStore } from '../store/workspace.js';
import { getStrokeColor, getModeStrokeColor } from '../utils/commonFunctions.js';
import { useRouter } from 'vue-router';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';

const modeStore = useModeStore();
const strokeColor = getStrokeColor();
const modeStrokeColor = getModeStrokeColor();
const workspaceStore = useWorkspaceStore();
const isHovered = ref(false);
const emit = defineEmits(['refresh-playlist-api']);
const router = useRouter();
const isAddRemoveWorkspaceLoading = ref(false);
const notificationTopBarStore = useNotificationTopBarStore();

const addToWorkspace = async (playlistId) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.post('api/v1/workspaces/playlists', {
            playlist_id: playlistId
        });
        workspaceStore.setAction('add');
        workspaceStore.setPlaylistId(playlistId);

        notificationTopBarStore.setIdUsedInAction(playlistId);
        notificationTopBarStore.setNotificationMessage('Ditambah ke Ruang Kerja');
        notificationTopBarStore.setNotificationType('addPlaylistToWorkspace');
        notificationTopBarStore.setIsShowNotificationTopBar(true);
        emit('refresh-playlist-api');
    } catch (error) {
        console.error('Error adding playlist to workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}


const removeFromWorkspace = async (playlistId) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.delete(`api/v1/workspaces/playlists/${playlistId}`);
        workspaceStore.setAction('remove');
        workspaceStore.setPlaylistId(playlistId);

        notificationTopBarStore.setIdUsedInAction(playlistId);
        notificationTopBarStore.setNotificationMessage('Dipindah dari Ruang Kerja');
        notificationTopBarStore.setNotificationType('removePlaylistFromWorkspace');
        notificationTopBarStore.setIsShowNotificationTopBar(true);
        emit('refresh-playlist-api');
    } catch (error) {
        console.error('Error removing playlist from workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}

// const gradeString = computed(() => props.grade.join(' | '));

const goToPlaylistPlay = () => {
    router.push({
        name: 'Play Playlist',
        params: { id: props.playlistId },
        query: { isTeacherPlaylist: props.isTeacherPlaylist }
    });
}
</script>

<template>
    <div 
    class="card card-compact rounded-[30px] p-[10px] group"
    :class="{'hover:bg-[#5E5E5E4D] hover:bg-opacity-30 text-black': modeStore.selectedMode === 'light', 'hover:bg-white hover:bg-opacity-10 text-white': modeStore.selectedMode === 'dark'}"
    >
        <figure class="w-full rounded-[30px] h-36 xl:h-48 2xl:h-72 cursor-pointer" @click="goToPlaylistPlay">
            <img class="w-full h-full object-cover bg-gray-400" 
                :src="image" alt="Playlist Card Image" />
        </figure>
        <div class="card-body" style="padding: 0; gap: 0; margin-top: 0.3rem;">
            <div class="flex justify-between items-start">
                <div class="">
                    <div class="flex flex-row items-start justify-start w-full">
                        <h2 class="text-lg leading-7 font-semibold cursor-pointer line-clamp-2 max-w-2/3 min-h-[60px]" @click="goToPlaylistPlay">{{ subject }}</h2>
                    </div>
                    <!-- <div class="pb-1 card-description">{{ gradeString }}</div> -->
                </div>
                <div class="flex items-start justify-end">
                    <div v-if="isAddedToWorkspace" @click="removeFromWorkspace(playlistId)"  class="my-auto cursor-pointer">
                        <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                        <svg v-show="!isAddRemoveWorkspaceLoading" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32.5 21.25V26.25C32.5 30.625 30 32.5 26.25 32.5H13.75C10 32.5 7.5 30.625 7.5 26.25V21.25C7.5 17.9375 8.9375 16.0625 11.25 15.35C12 15.1125 12.8375 15 13.75 15H26.25C27.1625 15 28 15.1125 28.75 15.35C31.0625 16.0625 32.5 17.9375 32.5 21.25Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M28.75 13.75V15.35C28 15.1125 27.1625 15 26.25 15H13.75C12.8375 15 12 15.1125 11.25 15.35V13.75C11.25 12.375 12.375 11.25 13.75 11.25H26.25C27.625 11.25 28.75 12.375 28.75 13.75Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M25 9.38751V11.25H15V9.38751C15 8.35001 15.85 7.5 16.8875 7.5H23.1125C24.15 7.5 25 8.35001 25 9.38751Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>  
                    </div>
                    <div v-else @click="addToWorkspace(playlistId)" class="my-auto cursor-pointer text-black" @mouseover="isHovered = true" @mouseout="isHovered = false">
                        <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                        <svg v-show="!isAddRemoveWorkspaceLoading" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32.5 21.25V26.25C32.5 30.625 30 32.5 26.25 32.5H13.75C10 32.5 7.5 30.625 7.5 26.25V21.25C7.5 17.9375 8.9375 16.0625 11.25 15.35C12 15.1125 12.8375 15 13.75 15H26.25C27.1625 15 28 15.1125 28.75 15.35C31.0625 16.0625 32.5 17.9375 32.5 21.25Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M28.75 13.75V15.35C28 15.1125 27.1625 15 26.25 15H13.75C12.8375 15 12 15.1125 11.25 15.35V13.75C11.25 12.375 12.375 11.25 13.75 11.25H26.25C27.625 11.25 28.75 12.375 28.75 13.75Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M25 9.38751V11.25H15V9.38751C15 8.35001 15.85 7.5 16.8875 7.5H23.1125C24.15 7.5 25 8.35001 25 9.38751Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>  
                    </div>
                </div>
            </div>

            <div 
                class="rounded-[30px] grid grid-cols-3 gap-2 py-2 px-2"
                :class="{'group-hover:bg-custom-light-gray': modeStore.selectedMode === 'light', 'group-hover:bg-custom-blue-gray': modeStore.selectedMode === 'dark'}"
            >
                <div class="playlist-card-description flex items-center justify-center">{{ documentCount }} {{ $t('general.document') }}</div>
                <div class="playlist-card-description flex items-center justify-center border-x-2">{{ interactionCount }} {{ $t('general.interactive') }}</div>
                <div class="playlist-card-description flex items-center justify-center">{{ videoCount }} {{ $t('general.video') }}</div>
            </div>
        </div>
    </div>
</template>