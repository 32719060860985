// utils/loadGapi.js
// export function loadGapi() {
//     return new Promise((resolve, reject) => {
//       if (window.gapi !== undefined) {
//         console.log('gapi already loaded');
//         console.log('gapi', window.gapi);
//         window.gapi.load('client:auth2', () => resolve(window.gapi));
//       } else {
//         console.log('loading gapi');
//         const script = document.createElement('script');
//         script.src = 'https://apis.google.com/js/api.js';
//         script.onload = () => {
//           window.gapi.load('client:auth2', () => resolve(window.gapi));
//         };
//         script.onerror = reject;
//         document.head.appendChild(script);
//         console.log('script', script);
//       }
//     });
//   }

export function loadGapi() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
      console.log('script', script);
    });
  }