<script setup>
// eslint-disable-next-line
const props = defineProps({
    id: Number,
    image: String,
    topic: String,
    groups: Array,
    subjects: Array,
    postedTime: String,
    tags: Array,
    isAddedToWorkspace: Boolean,
    contentType: Number
});

import axios from 'axios';
import { computed, ref, defineEmits } from 'vue';
import { useModeStore } from '../store/mode.js';
import { useThemeStore } from '../store/theme.js';
import useIsMobile from '../composables/isMobile.js';
import { getStrokeColor, getModeStrokeColor, getContentTypeLabel } from '../utils/commonFunctions.js';
import { useRouter } from 'vue-router';
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';
import { useDocumentPageFilterStore } from '../store/documentPageFilter.js';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';

// import AddToPlaylistDropdown from './AddToPlaylistDropdown.vue';

import playIcon from '../assets/icons/play_icon.png';

const addToPlaylistStore = useAddToPlaylistStore();
const documentPageFilterStore = useDocumentPageFilterStore();
const notificationTopBarStore = useNotificationTopBarStore();
const modeStore = useModeStore();
const themeStore = useThemeStore();
const { isMobile } = useIsMobile();
const strokeColor = getStrokeColor();
const modeStrokeColor = getModeStrokeColor();
const isHovered = ref(false);
const emit = defineEmits(['refresh-asset-api']);
const router = useRouter()

const displayTopic = computed(() => {
    return isMobile.value ? `${props.topic.slice(0, 10)}...` : props.topic;
});

function playButton() {
    // window.alert('Play button clicked');
}

const isAddRemoveWorkspaceLoading = ref(false);

const addToWorkspace = async (id) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        const response = await axios.post('api/v1/workspaces/asset_broadcasts', {
            asset_broadcast_id: id
        });

        // Check if the response indicates success
        if (response.status === 200 || response.status === 201) { 
            console.log('Added to workspace');

            // Set up notification after successful addition
            notificationTopBarStore.setIdUsedInAction(id);
            notificationTopBarStore.setNotificationMessage('Ditambah ke Ruang Kerja');
            notificationTopBarStore.setNotificationType('addAssetBroadcastToWorkspace');
            notificationTopBarStore.setIsShowNotificationTopBar(true);

            // Emit an event to refresh the asset API
            emit('refresh-asset-api');
        } else {
            console.error('Failed to add asset broadcast to workspace:', response);
        }
    } catch (error) {
        console.error('Error adding asset broadcast to workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}


const removeFromWorkspace = async (id) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.delete(`api/v1/workspaces/asset_broadcasts/${id}`);
        notificationTopBarStore.setIdUsedInAction(id);
        notificationTopBarStore.setNotificationMessage('Dipindah dari Ruang Kerja');
        notificationTopBarStore.setNotificationType('removeAssetBroadcastFromWorkspace');
        notificationTopBarStore.setIsShowNotificationTopBar(true);
        emit('refresh-asset-api');
    } catch (error) {
        console.error('Error removing playlist from workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}

const goToDocument = (id) => {
    router.push({ name: 'DocumentDetailed', params: { id: id } });
}

const showBarAndUpdateSelectedFile = (id) => {
    const checkbox = document.getElementById('checkbox-' + id);
    
    if (checkbox && checkbox.checked) {
        addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(true);
        addToPlaylistStore.setSelectedAssetBroadcastIds([...addToPlaylistStore.selectedAssetBroadcastIds, id]);
    } else { //unchecked
        addToPlaylistStore.setSelectedAssetBroadcastIds(addToPlaylistStore.selectedAssetBroadcastIds.filter(fileId => fileId !== id));
    }

    console.log('selectedAssetBroadcastIds', addToPlaylistStore.selectedAssetBroadcastIds);
}

const goToDocumentPageWithSubjectFilter = (id) => {
    console.log('goToDocumentPageWithSubjectFilter', id); //id here is 202, but in filter it is 133
    documentPageFilterStore.setIsFilterDisplay(true);
    documentPageFilterStore.setFilterBySubject(id);
    router.push('/document');
}

const goToDocumentPageWithCourseLvlFilter = (id) => {
    console.log('goToDocumentPageWithCourseLvlFilter', id); 
    documentPageFilterStore.setIsFilterDisplay(true);
    documentPageFilterStore.setFilterByCourseLevel(id);
    router.push('/document');
}

</script>

<template>
    <div 
        class="card card-compact rounded-xl p-2 bg-transparent"
        :class="{'hover:bg-white hover:bg-opacity-30 text-black': modeStore.selectedMode === 'light', 'hover:bg-white hover:bg-opacity-10 text-white': modeStore.selectedMode === 'dark'}"
    >
        <figure @click.prevent="goToDocument(id)" class="w-full relative rounded-xl h-44 xl:h-60 2xl:h-96 cursor-pointer">
            <img class="w-full h-full object-cover" :src="image" alt="Document Image" />
            <img class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-width-1/5 max-height-1/5 cursor-pointer"  @click="playButton" :src="playIcon" alt="Play Icon"/>
        </figure>
        <div :class="{ 'theme-green': themeStore.selectedTheme === 'green', 'theme-orange': themeStore.selectedTheme === 'orange', 'theme-blue': themeStore.selectedTheme === 'blue', 'theme-purple': themeStore.selectedTheme === 'purple', 'theme-darkpink': themeStore.selectedTheme === 'darkpink'  }" class="absolute  right-5 top-5 cursor-pointer">
            <input ref="checkbox" @click="showBarAndUpdateSelectedFile(props.id)" type="checkbox" :id="'checkbox-' + props.id" class="file-checkbox h-5 w-5"/>
            <!-- <AddToPlaylistDropdown :currentFileId="props.id"/> -->
        </div>
        <div class="flex flex-row">
            <div class="card-body basis-2/3 2xl:basis-4/5" style="padding: 0; gap: 0; margin-top: 0.3rem;">
                <route-link :to="{ name: 'DocumentDetailed', params: { id: id } }" class="card-title cursor-pointer min-h-[60px] line-clamp-2">{{ displayTopic }}</route-link>
                <!-- <p class="pb-1 card-description">{{ postedTime }}</p> -->
                <div v-if="(groups && groups.length > 0) || (subjects && subjects.length > 0)" class="flex pb-1">
                    <div v-if="groups && groups.length > 0">
                        <div  v-for="(group, index) in groups" :key="group.id" class="flex overflow-hidden">
                            <div class="cursor-pointer" @click="goToDocumentPageWithCourseLvlFilter(group.id)">{{ group.name }}</div><span v-if="index < groups.length - 1">, </span>
                        </div>
                    </div>
                    <div v-if="subjects && subjects.length > 0">
                        <span class="px-1"> | </span>
                    </div>
                    <div v-if="subjects && subjects.length > 0">
                        <div v-for="(subject, index) in subjects" :key="subject.id" class="flex overflow-hidden">
                            <div class="cursor-pointer" @click="goToDocumentPageWithSubjectFilter(subject.id)">{{ subject.name }}</div><span v-if="index < subjects.length - 1">, </span>
                        </div>
                    </div>
                </div>
            </div>
            <div id="step-7" class="flex flex-row justify-end items-start mt-[0.3rem]">
                <div v-if="isAddedToWorkspace" @click="removeFromWorkspace(id)"  class="cursor-pointer">
                    <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                    <svg v-show="!isAddRemoveWorkspaceLoading" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.5 21.25V26.25C32.5 30.625 30 32.5 26.25 32.5H13.75C10 32.5 7.5 30.625 7.5 26.25V21.25C7.5 17.9375 8.9375 16.0625 11.25 15.35C12 15.1125 12.8375 15 13.75 15H26.25C27.1625 15 28 15.1125 28.75 15.35C31.0625 16.0625 32.5 17.9375 32.5 21.25Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M28.75 13.75V15.35C28 15.1125 27.1625 15 26.25 15H13.75C12.8375 15 12 15.1125 11.25 15.35V13.75C11.25 12.375 12.375 11.25 13.75 11.25H26.25C27.625 11.25 28.75 12.375 28.75 13.75Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M25 9.38751V11.25H15V9.38751C15 8.35001 15.85 7.5 16.8875 7.5H23.1125C24.15 7.5 25 8.35001 25 9.38751Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>  
                </div>
                <div v-else @click="addToWorkspace(id)" class="cursor-pointer text-black" @mouseover="isHovered = true" @mouseout="isHovered = false">
                    <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                    <svg v-show="!isAddRemoveWorkspaceLoading" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.5 21.25V26.25C32.5 30.625 30 32.5 26.25 32.5H13.75C10 32.5 7.5 30.625 7.5 26.25V21.25C7.5 17.9375 8.9375 16.0625 11.25 15.35C12 15.1125 12.8375 15 13.75 15H26.25C27.1625 15 28 15.1125 28.75 15.35C31.0625 16.0625 32.5 17.9375 32.5 21.25Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M28.75 13.75V15.35C28 15.1125 27.1625 15 26.25 15H13.75C12.8375 15 12 15.1125 11.25 15.35V13.75C11.25 12.375 12.375 11.25 13.75 11.25H26.25C27.625 11.25 28.75 12.375 28.75 13.75Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M25 9.38751V11.25H15V9.38751C15 8.35001 15.85 7.5 16.8875 7.5H23.1125C24.15 7.5 25 8.35001 25 9.38751Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>  
                </div>
            </div>
        </div>
        <div class="flex flex-wrap gap-2">
            <!-- <div v-for="tag in tags" :key="tag" class="badge badge-lg border-0 rounded-xl bg-[#FEF3C7] text-[#92400E] h-auto">{{ tag.name }}</div> -->
            <div v-if="contentType" class="badge badge-lg border-0 rounded-xl h-auto"
            :class="{
            'bg-[#FEF3C7] text-[#92400E]': contentType === 1,
            'bg-green-100 text-green-800': contentType === 2,
            'bg-purple-100 text-purple-800': contentType === 3
        }"
            >
            {{ getContentTypeLabel(contentType) }}
            </div>
        </div>
    </div>
</template>