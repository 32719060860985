import { createRouter, createWebHistory, START_LOCATION } from 'vue-router'
import {loadLayoutMiddleware} from "@/router/middleware/loadLayoutMiddleware"

import { useUserStore } from '../store/user';
import { usePlaybackStore } from '@/store/playbackStore';
import DashboardPage from '../views/DashboardPage.vue'
import BrowsePage from '../views/BrowsePage.vue'
import ApplicationPage from '../views/ApplicationPage.vue'
import DocumentPage from '../views/DocumentPage.vue'
import DoocumentDetailed from '../views/DoocumentDetailed.vue'
import WorkspacePage from '../views/WorkspacePage.vue'
import PlaylistCreate from '../views/PlaylistCreate.vue'
import PlaylistDetails from '../views/PlaylistDetails.vue'
import PlaylistPage from '../views/PlaylistPage.vue'
import PlaylistPlay from '../views/PlaylistPlay.vue'
import PodcastPage from '../views/PodcastPage.vue'
import FeedbackPage from '../views/FeedbackPage.vue'
import SettingPage from '../views/SettingPage.vue'
// import LoginPage from '../views/LoginPage.vue'
import NewLoginPage from '../views/NewLoginPage.vue'
import TeachersPage from '../views/TeachersPage.vue'
import TeacherDetailPage from '../views/TeacherDetailPage.vue'
import AllSearchResultPage from '../views/AllSearchResultPage.vue'
import ErrorPage from '../views/ErrorPage.vue'

const routes = [
    {
        path: '/login',
        name: 'Login Page',
        component: NewLoginPage,
        meta: {
            guest: true,
            layout: 'GuestLayout'
        }
    },
    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: '/dashboard',
        name: 'Dashboard Page',
        component: DashboardPage,
        meta: { 
            requiresAuth: true,
            layout: 'DefaultLayout'
        },
    },
    {
        path: '/browse',
        name: 'Browse Page',
        component: BrowsePage,
        meta: { 
            requiresAuth: true,
            layout: 'DefaultLayout'
        },
    },
    {
        path: '/application',
        name: 'Application Page',
        component: ApplicationPage,
        meta: { 
            requiresAuth: true,
            layout: 'DefaultLayout'
        },
    },
    {
        path: '/document',
        name: 'Document Page',
        component: DocumentPage,
        meta: { 
            requiresAuth: true,
            layout: 'DefaultLayout'
        },
    },
    {
        path: '/workspace',
        name: 'Workspace Page',
        component: WorkspacePage,
        meta: { 
            requiresAuth: true,
            layout: 'DefaultLayout'
        },
    },
    {
        path: '/playlist',
        name: 'Playlist Page',
        component: PlaylistPage,
        meta: { 
            requiresAuth: true,
            layout: 'DefaultLayout'
         },
    },
    {
        path: '/playlist/detail',
        name: 'Playlist Detail',
        component: PlaylistDetails,
        props: route => ({
            id: route.query.id,
            isTeacherPlaylist: route.query.isTeacherPlaylist
        }),
        meta: { 
            requiresAuth: true,
            layout: 'DefaultLayout'
        },
    },
    {
        path: '/playlist/create',
        name: 'Create Playlist',
        component: PlaylistCreate,
        props: route => ({
            id: route.query.id,
        }),
        meta: { 
            requiresAuth: true,
            layout: 'DefaultLayout'
        },
    },
    {
        path: '/playlist/play/:id',
        name: 'Play Playlist',
        component: PlaylistPlay,
        props: route => ({
            id: route.params.id,
            isTeacherPlaylist: route.query.isTeacherPlaylist,
            assetBroadcastId: route.query.assetBroadcastId
        }),
        meta: { 
            requiresAuth: true,
            layout: 'DefaultLayout'
        },
    },
    {
        path: '/documents/:id',
        name: 'DocumentDetailed',
        component: DoocumentDetailed,
        props: route => ({
            id: route.params.id
        }),
        meta: { 
            requiresAuth: true,
            layout: 'DefaultLayout'
        },
    },
    {
        path: '/podcast',
        name: 'Podcast Page',
        component: PodcastPage,
        meta: { 
            requiresAuth: true,
            layout: 'DefaultLayout'
        },
    },
    {
        path: '/feedback',
        name: 'Feedback Page',
        component: FeedbackPage,
        meta: { 
            requiresAuth: true,           
            layout: 'DefaultLayout'
        },
    },
    {
        path: '/setting',
        name: 'Setting Page',
        component: SettingPage,
        meta: { 
            requiresAuth: true,
            layout: 'DefaultLayout'
        },
    },
    {
        path: '/teachers',
        name: 'Teachers Page',
        component: TeachersPage,
        meta: { 
            requiresAuth: true,
            layout: 'DefaultLayout'
        },
    },
    {
        path: '/teachers/:id',
        name: 'Teacher Detail',
        component: TeacherDetailPage,
        meta: { 
            requiresAuth: true,
            layout: 'DefaultLayout'
        },
    },
    {
        path: '/search',
        name: 'All Search Result Page',
        component: AllSearchResultPage,
        meta: { 
            requiresAuth: true,
            layout: 'DefaultLayout'
        },
    },
    {
        path: '/error',
        name: 'ErrorPage',
        component: ErrorPage,
        props: route => ({
          errorTitle: route.query.title,
          errorMessage: route.query.message
        }),
        meta: { 
            requiresAuth: false,
            layout: 'DefaultLayout'
        },
      },
      {
        path: '/:catchAll(.*)',
        redirect: '/error',
      }
]

const router = createRouter({
    history: createWebHistory('/'),
    routes,
    scrollBehavior() {
      return { top: 0 };
    },
  });
  
  // Navigation guard to check for authentication
  router.beforeEach(async (to, from, next) => {
        await loadLayoutMiddleware(to);
        
    const userStore = useUserStore();
    const playbackStore = usePlaybackStore();

        const isAuthenticated = userStore.isLoggedIn;

        // on login, show chatwoot
        if (from.name === 'Login Page' && to.name !== 'Login Page') { // show when login
            if (typeof window.$chatwoot === 'undefined') {
                window.$chatwoot.toggleBubbleVisibility("show");
            }
        }

        if (to.name == 'Login Page' && from.name !== 'Login Page') { // hiden when logout
            if (typeof window.$chatwoot === 'undefined') {
                window.$chatwoot.toggleBubbleVisibility("hide");
            }
        }

        if (from === START_LOCATION) {
            console.log('Navigated from start location');
            playbackStore.updatePlayingState(false);
        }

        // if navigate away from a Document Detailed or Playlist Play, stop the video
        if (from.name === 'DocumentDetailed' || from.name === 'Play Playlist') {
            playbackStore.updatePlayingState(false);
        }
    
        if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated) {
        next({ path: '/login' });
        } else if (to.matched.some((record) => record.meta.guest) && isAuthenticated) {
        next({ path: '/dashboard' });
        } else {
        next();
        }
    });

  export default router;