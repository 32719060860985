<script setup>
import { useModeStore } from '../store/mode.js';
import { useThemeStore } from '../store/theme.js';
import { getModeStrokeColor } from '../utils/commonFunctions.js';
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';
import { ref, onMounted } from 'vue';
import axios from 'axios';

const modeStore = useModeStore();
const themeStore = useThemeStore();
const modeStrokeColor = getModeStrokeColor();
const addToPlaylistStore = useAddToPlaylistStore();

const closeAddOrCreatePlaylistTopBar = () => {
    addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(false);
    addToPlaylistStore.setShowAddToPlaylistDropdown(false);
    addToPlaylistStore.setSelectedAssetBroadcastIds([]);
    addToPlaylistStore.setSelectedPlaylistIds([]);
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
    });
}
const showDropdown = () => {
    addToPlaylistStore.setShowAddToPlaylistDropdown(true)
}

const showCreateEditPlaylistModal = () => {
    addToPlaylistStore.setModalMode('create')
    addToPlaylistStore.setRedirectToCreatePlaylistPage(false)
    addToPlaylistStore.setShowCreateOrEditPlaylistModal(true)
}

const isPlaylistDataLoading = ref(false);
const myPlaylistData = ref([]);
const fetchMyPlaylist = (page = 1) => {
    isPlaylistDataLoading.value = true;
    return axios.get(`api/v1/user-playlists?page=${page}`)
        .then((response) => {
            console.log(response.data);
            myPlaylistData.value = response.data.data;
            console.log('addorcreateplaylisttopbar fetchMyPlaylist myPlaylistData', myPlaylistData.value.length);
            return response.data.data;
        })
        .catch((error) => {
            console.log(error);
            return [];
        })
        .finally(() => {
            isPlaylistDataLoading.value = false;
        });
}

onMounted(() => {
    fetchMyPlaylist();
});

</script>

<template>
    <div class="flex justify-center w-full fixed left-0 md:top-[80px] z-10">
        <div :class="{ 'bg-[#14B8A6]': (themeStore.selectedTheme === 'green' && modeStore.selectedMode === 'dark'), 'bg-[#F97316]': (themeStore.selectedTheme === 'orange' && modeStore.selectedMode === 'dark'), 'bg-[#3B82F6]': (themeStore.selectedTheme === 'blue' && modeStore.selectedMode === 'dark'), 'bg-[#8B5CF6]': (themeStore.selectedTheme === 'purple' && modeStore.selectedMode === 'dark'), 'bg-[#DB2777]': (themeStore.selectedTheme === 'darkpink' && modeStore.selectedMode === 'dark'), 'bg-white' : modeStore.selectedMode === 'light'}" class="backdrop-blur bg-opacity-20 flex gap-0 md:gap-4 justify-between rounded-md px-4 py-2 md:min-w-[800px]">
            <div class="my-auto text-xs md:text-base flex items-center gap-2">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 16.499C13.125 16.499 16.5 13.124 16.5 8.99902C16.5 4.87402 13.125 1.49902 9 1.49902C4.875 1.49902 1.5 4.87402 1.5 8.99902C1.5 13.124 4.875 16.499 9 16.499Z" :stroke=modeStrokeColor stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 5.99902V9.74902" :stroke=modeStrokeColor stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.99609 12.001H9.00283" :stroke=modeStrokeColor stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <div :class="modeStore.selectedMode === 'dark'? 'text-white': 'text-black'" class="my-auto text-xs md:text-sm">Kreatif Dengan Playlist Pembelajaran Anda !</div> 
            </div>
            <div class="flex justify-start md:justify-end items-center gap-1 md:gap-3 my-auto">
                <button @click="showDropdown()" v-if="myPlaylistData && myPlaylistData.length > 0" class="bg-black px-4 py-2 text-xs md:text-sm text-white rounded-md text-center my-auto">Tambah ke Playlist</button>
                <button v-else disabled class="px-4 py-2 text-xs md:text-sm text-gray-400 rounded-md text-center my-auto bg-gray-500 cursor-not-allowed">Tambah ke Playlist</button>
                <div @click="showCreateEditPlaylistModal()" :data-theme="themeStore.selectedTheme" class="py-2 px-4 rounded-md text-white text-xs md:text-sm cursor-pointer text-center my-auto"><span class="text-sm">+</span> Cipta Playlist</div>
                <i @click="closeAddOrCreatePlaylistTopBar()" class="fa fa-close my-auto fa-lg"></i>
            </div>
        </div>
    </div>
</template>