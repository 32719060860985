<script setup>
// eslint-disable-next-line
defineProps({
    playlistId: Number,
    playlistThumbnail: String,
    assetBroadcastThumbnails: Array,
    subject: String,
    documentCount: Number,
    interactionCount: Number,
    videoCount: Number,
    isAddedToWorkspace: Boolean,
    isTeacherPlaylist: Boolean //this field is added to differentiate between teacher and user playlist as we need to route to playlist details page
});

import axios from 'axios';
import { ref, defineEmits } from 'vue';
import { useModeStore } from '../store/mode.js';
import { getStrokeColor, getModeStrokeColor } from '../utils/commonFunctions.js';
import router from "../router/index.js";
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';

const notificationTopBarStore = useNotificationTopBarStore();
const modeStore = useModeStore();
const strokeColor = getStrokeColor();
const modeStrokeColor = getModeStrokeColor();
const isHovered = ref(false);
const isDeleteHovered = ref(false);
const emit = defineEmits(['refresh-teacher-playlist-api']);

// const addToWorkspace = (playlistId) => {
//     axios.post('api/v1/workspaces/playlists', {
//         playlist_id: playlistId
//     });

//     emit('refresh-teacher-playlist-api');
// }

// const removeFromWorkspace = (playlistId) => {
//     axios.delete(`api/v1/workspaces/playlists/${playlistId}`);

//     emit('refresh-teacher-playlist-api');
// }

const isAddRemoveWorkspaceLoading = ref(false);

const addToWorkspace = async (playlistId) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.post('api/v1/workspaces/playlists', {
            playlist_id: playlistId
        });
        notificationTopBarStore.setIdUsedInAction(playlistId);
        notificationTopBarStore.setNotificationMessage('Ditambah ke Ruang Kerja');
        notificationTopBarStore.setNotificationType('addPlaylistToWorkspace');
        notificationTopBarStore.setIsShowNotificationTopBar(true);
        emit('refresh-teacher-playlist-api');
    } catch (error) {
        console.error('Error adding playlist to workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}


const removeFromWorkspace = async (playlistId) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.delete(`api/v1/workspaces/playlists/${playlistId}`);
        notificationTopBarStore.setIdUsedInAction(playlistId);
        notificationTopBarStore.setNotificationMessage('Dipindah dari Ruang Kerja');
        notificationTopBarStore.setNotificationType('removePlaylistFromWorkspace');
        notificationTopBarStore.setIsShowNotificationTopBar(true);
        emit('refresh-teacher-playlist-api');
    } catch (error) {
        console.error('Error removing playlist from workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}

const goToPlaylistDetailsPage = (playlistId, isTeacherPlaylist) => {
    router.push({
            name: 'Playlist Detail',
            query: {
                id: playlistId,
                isTeacherPlaylist: isTeacherPlaylist
            }
        });
}

const isDeleteLoading = ref(false);
const deletePlaylist = async (playlistId) => {
    try {
        isDeleteLoading.value = true;
        await axios.delete(`api/v1/user-playlists/${playlistId}`);
        emit('refresh-teacher-playlist-api');
    } catch (error) {
        console.error('Error deleting playlist:', error);
    } finally {
        isDeleteLoading.value = false;
    }
}
</script>

<template>
    <div 
        class="card card-compact rounded-[30px] p-[10px] group"
        :class="{'hover:bg-[#5E5E5E4D] hover:bg-opacity-30 text-black': modeStore.selectedMode === 'light', 'hover:bg-white hover:bg-opacity-10 text-white': modeStore.selectedMode === 'dark'}"
    >
        
        <!-- Image container -->
        <div @click="goToPlaylistDetailsPage(playlistId, isTeacherPlaylist)" class="rounded-[30px] overflow-hidden cursor-pointer mb-2">
            <div
                class="w-full rounded-[30px] overflow-hidden"
                :class="assetBroadcastThumbnails ? 'h-40 xl:h-44 2xl:h-60' : 'h-40 xl:h-44 2xl:h-60'"
            >
                <img class="w-full bg-gray-400" 
                    style="object-fit: cover; object-position: center;"
                    :src="playlistThumbnail" alt="Playlist Card Image" />
            </div>

            <!-- Video Thumbnails -->
            <!-- <div v-if="assetBroadcastThumbnails" class="flex flex-row w-full border-t-4 border-gray-600 h-16 xl:h-16 2xl:h-20">
                <img class="w-1/3 object-cover" :src="assetBroadcastThumbnails[0].url" alt="Asset thumbnail" />
                <img class="w-1/3 object-cover border-x-4 border-gray-600" :src="assetBroadcastThumbnails[1].url" alt="Asset thumbnail" />
                <img class="w-1/3 object-cover" :src="assetBroadcastThumbnails[2].url" alt="Asset thumbnail" />
            </div> -->
        </div>

        <!-- Body -->
        <div class="card-body" style="padding: 0; gap: 0; margin-top: 0.3rem;">
            <!-- <div class="flex flex-row">
                
                <h2 @click="goToPlaylistDetailsPage(playlistId, isTeacherPlaylist)" class="card-title basis-5/6 2xl:basis-[90%] cursor-pointer">{{ subject }}</h2>
                <div class="flex py-2">
                    <div v-if="isAddedToWorkspace" @click="removeFromWorkspace(playlistId)"  class="my-auto cursor-pointer">
                        <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                        <svg v-show="!isAddRemoveWorkspaceLoading" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32.5 21.25V26.25C32.5 30.625 30 32.5 26.25 32.5H13.75C10 32.5 7.5 30.625 7.5 26.25V21.25C7.5 17.9375 8.9375 16.0625 11.25 15.35C12 15.1125 12.8375 15 13.75 15H26.25C27.1625 15 28 15.1125 28.75 15.35C31.0625 16.0625 32.5 17.9375 32.5 21.25Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M28.75 13.75V15.35C28 15.1125 27.1625 15 26.25 15H13.75C12.8375 15 12 15.1125 11.25 15.35V13.75C11.25 12.375 12.375 11.25 13.75 11.25H26.25C27.625 11.25 28.75 12.375 28.75 13.75Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M25 9.38751V11.25H15V9.38751C15 8.35001 15.85 7.5 16.8875 7.5H23.1125C24.15 7.5 25 8.35001 25 9.38751Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>  
                    </div>
                    <div v-else @click="addToWorkspace(playlistId)" class="my-auto cursor-pointer text-black" @mouseover="isHovered = true" @mouseout="isHovered = false">
                        <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                        <svg v-show="!isAddRemoveWorkspaceLoading" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32.5 21.25V26.25C32.5 30.625 30 32.5 26.25 32.5H13.75C10 32.5 7.5 30.625 7.5 26.25V21.25C7.5 17.9375 8.9375 16.0625 11.25 15.35C12 15.1125 12.8375 15 13.75 15H26.25C27.1625 15 28 15.1125 28.75 15.35C31.0625 16.0625 32.5 17.9375 32.5 21.25Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M28.75 13.75V15.35C28 15.1125 27.1625 15 26.25 15H13.75C12.8375 15 12 15.1125 11.25 15.35V13.75C11.25 12.375 12.375 11.25 13.75 11.25H26.25C27.625 11.25 28.75 12.375 28.75 13.75Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M25 9.38751V11.25H15V9.38751C15 8.35001 15.85 7.5 16.8875 7.5H23.1125C24.15 7.5 25 8.35001 25 9.38751Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>  
                    </div>
                    <div @click="deletePlaylist(playlistId)" v-if="!isTeacherPlaylist" class="my-auto" @mouseover="isDeleteHovered = true" @mouseout="isDeleteHovered = false">
                        <div v-show="isDeleteLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                        <svg v-show="!isDeleteLoading" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.5 5L15.6327 17.1425C15.5579 18.1891 14.687 19 13.6378 19H5.36224C4.31296 19 3.44208 18.1891 3.36732 17.1425L2.5 5M7.5 9V15M11.5 9V15M12.5 5V2C12.5 1.44772 12.0523 1 11.5 1H7.5C6.94772 1 6.5 1.44772 6.5 2V5M1.5 5H17.5" :stroke="isDeleteHovered ? strokeColor : modeStrokeColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div> -->
            <div class="flex gap-1 items-start justify-between">
                <h2 @click="goToPlaylistDetailsPage(playlistId, isTeacherPlaylist)" class="text-lg font-semibold text-left cursor-pointer min-h-[60px]">{{ subject }}</h2>
                <div class="flex">
                    <div>
                        <div v-if="isAddedToWorkspace" @click="removeFromWorkspace(playlistId)"  class=" cursor-pointer">
                            <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                            <!-- <svg v-show="!isAddRemoveWorkspaceLoading" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32.5 21.25V26.25C32.5 30.625 30 32.5 26.25 32.5H13.75C10 32.5 7.5 30.625 7.5 26.25V21.25C7.5 17.9375 8.9375 16.0625 11.25 15.35C12 15.1125 12.8375 15 13.75 15H26.25C27.1625 15 28 15.1125 28.75 15.35C31.0625 16.0625 32.5 17.9375 32.5 21.25Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M28.75 13.75V15.35C28 15.1125 27.1625 15 26.25 15H13.75C12.8375 15 12 15.1125 11.25 15.35V13.75C11.25 12.375 12.375 11.25 13.75 11.25H26.25C27.625 11.25 28.75 12.375 28.75 13.75Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M25 9.38751V11.25H15V9.38751C15 8.35001 15.85 7.5 16.8875 7.5H23.1125C24.15 7.5 25 8.35001 25 9.38751Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>   -->
                            <svg v-show="!isAddRemoveWorkspaceLoading" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.75 5.46875H6.25C5.82292 5.46875 5.46875 5.11458 5.46875 4.6875C5.46875 4.26042 5.82292 3.90625 6.25 3.90625H18.75C19.1771 3.90625 19.5312 4.26042 19.5312 4.6875C19.5312 5.11458 19.1771 5.46875 18.75 5.46875Z" :fill="strokeColor"/>
                            <path d="M15.625 2.86523H9.375C8.94792 2.86523 8.59375 2.51107 8.59375 2.08398C8.59375 1.6569 8.94792 1.30273 9.375 1.30273H15.625C16.0521 1.30273 16.4062 1.6569 16.4062 2.08398C16.4062 2.51107 16.0521 2.86523 15.625 2.86523Z" :fill="strokeColor"/>
                            <path d="M14.4896 16.8848C14.1354 16.8848 13.8438 17.1764 13.8438 17.5306C13.8438 17.8848 14.1354 18.1764 14.4896 18.1764C14.8437 18.1764 15.1354 17.8848 15.1354 17.5306C15.1354 17.1764 14.8437 16.8848 14.4896 16.8848Z" :fill="strokeColor"/>
                            <path d="M9.9069 18.4792C9.9069 18.125 9.61523 17.8438 9.26107 17.8438C8.9069 17.8438 8.61523 18.1354 8.61523 18.4896C8.61523 18.8438 8.9069 19.1354 9.26107 19.1354C9.61523 19.125 9.9069 18.8333 9.9069 18.4792Z" :fill="strokeColor"/>
                            <path d="M18.7507 7.29102H6.25065C3.95898 7.29102 2.08398 9.16602 2.08398 11.4577V18.7493C2.08398 21.041 3.95898 22.916 6.25065 22.916H18.7507C21.0423 22.916 22.9173 21.041 22.9173 18.7493V11.4577C22.9173 9.16602 21.0423 7.29102 18.7507 7.29102Z" :fill="strokeColor"/>
                            </svg>
                        </div>
                        <div v-else @click="addToWorkspace(playlistId)" class=" cursor-pointer text-black" @mouseover="isHovered = true" @mouseout="isHovered = false">
                            <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                            <!-- <svg v-show="!isAddRemoveWorkspaceLoading" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32.5 21.25V26.25C32.5 30.625 30 32.5 26.25 32.5H13.75C10 32.5 7.5 30.625 7.5 26.25V21.25C7.5 17.9375 8.9375 16.0625 11.25 15.35C12 15.1125 12.8375 15 13.75 15H26.25C27.1625 15 28 15.1125 28.75 15.35C31.0625 16.0625 32.5 17.9375 32.5 21.25Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M28.75 13.75V15.35C28 15.1125 27.1625 15 26.25 15H13.75C12.8375 15 12 15.1125 11.25 15.35V13.75C11.25 12.375 12.375 11.25 13.75 11.25H26.25C27.625 11.25 28.75 12.375 28.75 13.75Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M25 9.38751V11.25H15V9.38751C15 8.35001 15.85 7.5 16.8875 7.5H23.1125C24.15 7.5 25 8.35001 25 9.38751Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>  -->
                            <svg v-show="!isAddRemoveWorkspaceLoading" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.75 5.46875H6.25C5.82292 5.46875 5.46875 5.11458 5.46875 4.6875C5.46875 4.26042 5.82292 3.90625 6.25 3.90625H18.75C19.1771 3.90625 19.5312 4.26042 19.5312 4.6875C19.5312 5.11458 19.1771 5.46875 18.75 5.46875Z" :fill="isHovered ? strokeColor : modeStrokeColor"/>
                            <path d="M15.625 2.86523H9.375C8.94792 2.86523 8.59375 2.51107 8.59375 2.08398C8.59375 1.6569 8.94792 1.30273 9.375 1.30273H15.625C16.0521 1.30273 16.4062 1.6569 16.4062 2.08398C16.4062 2.51107 16.0521 2.86523 15.625 2.86523Z" :fill="isHovered ? strokeColor : modeStrokeColor"/>
                            <path d="M14.4896 16.8848C14.1354 16.8848 13.8438 17.1764 13.8438 17.5306C13.8438 17.8848 14.1354 18.1764 14.4896 18.1764C14.8437 18.1764 15.1354 17.8848 15.1354 17.5306C15.1354 17.1764 14.8437 16.8848 14.4896 16.8848Z" :fill="isHovered ? strokeColor : modeStrokeColor"/>
                            <path d="M9.9069 18.4792C9.9069 18.125 9.61523 17.8438 9.26107 17.8438C8.9069 17.8438 8.61523 18.1354 8.61523 18.4896C8.61523 18.8438 8.9069 19.1354 9.26107 19.1354C9.61523 19.125 9.9069 18.8333 9.9069 18.4792Z" :fill="isHovered ? strokeColor : modeStrokeColor"/>
                            <path d="M18.7507 7.29102H6.25065C3.95898 7.29102 2.08398 9.16602 2.08398 11.4577V18.7493C2.08398 21.041 3.95898 22.916 6.25065 22.916H18.7507C21.0423 22.916 22.9173 21.041 22.9173 18.7493V11.4577C22.9173 9.16602 21.0423 7.29102 18.7507 7.29102Z" :fill="isHovered ? strokeColor : modeStrokeColor"/>
                            </svg>
 
                        </div>
                    </div>

                    <div @click="deletePlaylist(playlistId)" v-if="!isTeacherPlaylist" class="" @mouseover="isDeleteHovered = true" @mouseout="isDeleteHovered = false">
                        <div v-show="isDeleteLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                        <svg v-show="!isDeleteLoading" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.875 6.22786C18.4062 5.88411 14.9167 5.70703 11.4375 5.70703C9.375 5.70703 7.3125 5.8112 5.25 6.01953L3.125 6.22786" :stroke="isDeleteHovered ? strokeColor : modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.85352 5.17578L9.08268 3.8112C9.24935 2.82161 9.37435 2.08203 11.1348 2.08203H13.8639C15.6243 2.08203 15.7598 2.86328 15.916 3.82161L16.1452 5.17578" :stroke="isDeleteHovered ? strokeColor : modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M19.6361 9.51953L18.959 20.0091C18.8444 21.6445 18.7506 22.9154 15.8444 22.9154H9.1569C6.25065 22.9154 6.1569 21.6445 6.04232 20.0091L5.36523 9.51953" :stroke="isDeleteHovered ? strokeColor : modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.7598 17.1875H14.2285" :stroke="isDeleteHovered ? strokeColor : modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.89648 13.0195H15.1048" :stroke="isDeleteHovered ? strokeColor : modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <!-- <svg v-show="!isDeleteLoading" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.5 5L15.6327 17.1425C15.5579 18.1891 14.687 19 13.6378 19H5.36224C4.31296 19 3.44208 18.1891 3.36732 17.1425L2.5 5M7.5 9V15M11.5 9V15M12.5 5V2C12.5 1.44772 12.0523 1 11.5 1H7.5C6.94772 1 6.5 1.44772 6.5 2V5M1.5 5H17.5" :stroke="isDeleteHovered ? strokeColor : modeStrokeColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> -->
                    </div>
                </div>
            </div>
            <div 
                class="rounded-[30px] grid grid-cols-3 gap-2 py-2 px-2 bg-transparent"
                :class="{'group-hover:bg-custom-light-gray': modeStore.selectedMode === 'light', 'group-hover:bg-custom-blue-gray': modeStore.selectedMode === 'dark'}"
            >
                <div class="playlist-card-description flex items-center justify-center">{{ documentCount }} {{ $t('general.document') }}</div>
                <div class="playlist-card-description flex items-center justify-center border-x-2">{{ interactionCount }} {{ $t('general.interactive') }}</div>
                <div class="playlist-card-description flex items-center justify-center">{{ videoCount }} {{ $t('general.video') }}</div>
            </div>
        </div>
    </div>
</template>