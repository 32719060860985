import { defineStore } from 'pinia'

export const useRemoveFromPlaylistStore = defineStore('removeFromPlaylist', {
    state: () => {
      return {
        playlistIdToRemoveFrom: null,
        fileIdToRemove: null,
        isShowRemoveFromPlaylistConfirmationModal: false,
      }
    },
    actions: {
    },
  })